<template>
	<LayoutOffcanvasStandard ref="offcanvas" title="Cambiar nombre" id="search-modal-edit">
		<template #body>
			<form class="vstack gap-5" @submit.prevent="submit" autocomplete="off">
				<CommonFormInputText v-model="form.title" title="Título" placeholder="Ingrese un título" :focus="true" :validation="validation.title" />
			</form>
		</template>
		<template #footer>
			<button type="button" class="btn btn-primary rounded-pill" :disabled="submitting" @click="submit">Guardar</button>
		</template>
	</LayoutOffcanvasStandard>
</template>

<script>
	import { ref, reactive, onMounted } from 'vue';
	import store from '@/store';
	import useVuelidate from '@vuelidate/core';
	import { required, minLength, maxLength } from '@/helpers/i18n/validators';
	import composableForm from '@/composables/form';
	import SearchRepository from '@/repositories/SearchRepository';

	export default {
		props: {
			search: Object
		},
		setup(props) {
			const form = reactive({});
			const { submitting, catchError } = composableForm();

			const offcanvas = ref(null);
			const show = () => offcanvas.value.offcanvas.show();

			const validation = useVuelidate({
				title: {
					required,
					minLength: minLength(3),
					maxLength: maxLength(255)
				}
			}, form);

			const submit = async () => {
				if(submitting.value) return;
				if(!await validation.value.$validate()) return;

				submitting.value = true;

				await SearchRepository.update(props.search.uuid, form).then((response) => {
					store.dispatch('auth/upsertSearch', response.data);
					offcanvas.value.offcanvas.hide();
				}).catch((e) => {
					Object.assign(form, props.search);
					catchError(e, true);
				});

				submitting.value = false;
			}

			onMounted(() => {
				Object.assign(form, props.search);

				document.getElementById('search-modal-edit').addEventListener('shown.bs.offcanvas', () => {
					document.querySelector('#search-modal-edit input').focus();
				});
			});

			return { offcanvas, show, validation, form, submitting, submit };
		}
	}
</script>