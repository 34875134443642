import { createApp } from 'vue';
import App from './App.vue';

//Styles
import '@/assets/css/customization.scss';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'vue3-carousel/dist/carousel.css';

const app = createApp({
	extends: App,
	methods: {
		checkUser() {
			if(store.getters['auth/user']) store.dispatch('auth/me');
		},
		documentHeight() {
			document.documentElement.style.setProperty('--doc-height', `${window.innerHeight}px`);
		}
	},
	async beforeMount() {
		window.addEventListener('resize', this.documentHeight);
		this.documentHeight();

		setInterval(this.checkUser, (5 * 60000));
		this.checkUser();
	}
});

//Vue Store
import store from '@/store';
app.use(store);

//Configuration
import { loadConfiguration, loadManifest } from '@/plugins/Configuration';
await loadConfiguration(app);
await loadManifest(app);

//Vue Router
import router from '@/router';
app.use(router);

//Traducciones
import i18n from '@/helpers/i18n/init.js';
app.use(i18n);

//Moment Js
import moment from 'moment';
import i18n_es from '@/helpers/i18n/translations/es.json';
moment.locale('es', i18n_es.moment);

//Vue Number Format
import VueNumberFormat from 'vue-number-format';
app.use(VueNumberFormat, {
	prefix: '',
	thounsand: '.',
	decimal: ','
});

//Vue Google Login
import vue3GoogleLogin from 'vue3-google-login';
app.use(vue3GoogleLogin, { clientId: app.config.globalProperties.$env.GOOGLE_CLIENT_ID });

//Vue Google Analytics
import VueGtag from 'vue-gtag-next';
app.use(VueGtag, { property: { id: app.config.globalProperties.$env.GOOGLE_ANALYTICS_ID }});

//Vue Toast
import Toast from 'vue-toastification';
app.use(Toast, {
	position: 'top-center',
	transition: 'Vue-Toastification__fade',
	timeout: 3000,
	closeOnClick: true,
	pauseOnFocusLoss: true,
	pauseOnHover: true,
	draggable: true,
	showCloseButtonOnHover: false,
	closeButton: false,
	icon: false
});

//Focus directive
app.directive('focus', {
	mounted(el, active) {
		if(active.value == undefined || active.value == true) el.focus();
	}
});

//Tooltip directive
import { Tooltip } from 'bootstrap';
app.directive('tooltip', {
	mounted(el, binding) {
		el._tooltip = new Tooltip(el, { title: binding.value || '', placement: binding.arg || 'top' });
	},
	beforeUnmount(el) {
		if(el._tooltip) el._tooltip.dispose();
	}
});

//Placeholder directive
import assetsImgIconsFail from '@/assets/img/icons/fail.jpg';
app.directive('placeholder-image', {
	mounted(el) {
		let parent = el.parentNode;

		el.style.opacity = 0;
		el.classList.add('placeholder');
		if(parent) parent.classList.add('placeholder-glow');

		el.onload = () => {
			el.style.opacity = 1;
			el.classList.remove('placeholder');
			if(parent) parent.classList.remove('placeholder-glow');
		}

		el.onerror = () => {
			el.src = assetsImgIconsFail;
		}
	}
});

//Recarga el sitio cuando exista una nueva versión
if('serviceWorker' in navigator) navigator.serviceWorker.addEventListener('controllerchange', () => {
	window.location.reload();
});

app.mount('body');
