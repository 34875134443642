import { ref, computed, watch } from 'vue';

export default function(emit) {
	const card = ref(null);
	const image = ref(null);
	const circleLike = ref(null);
	const circleReject = ref(null);
	const isDragging = ref(false);
	const isDeciding = ref(null);
	const isZooming = ref(false);
	const activePointers = ref(new Map());
	const initialCenter = ref({ clientX: 0, clientY: 0 });
	const initialDistance = ref(0);
	const draggingSize = 130;
	const transition = 150;
	const startX = ref(0);
	const startY = ref(0);
	const moveX = ref(0);
	const moveY = ref(0);

	const calculateDistance = () => {
		let pointersArray = Array.from(activePointers.value.values()),
			[ pointer1, pointer2 ] = pointersArray;

		return Math.sqrt(Math.pow(pointer2.clientX - pointer1.clientX, 2) + Math.pow(pointer2.clientY - pointer1.clientY, 2));
	}

	const calculateCenter = () => {
		let pointersArray = Array.from(activePointers.value.values()),
			[ pointer1, pointer2 ] = pointersArray,
			clientX = (pointer1.clientX + pointer2.clientX) / 2,
			clientY = (pointer1.clientY + pointer2.clientY) / 2;

		return {
			clientX: clientX ?? 0,
			clientY: clientY ?? 0
		};
	}

	const imageTransform = computed(() => {
		if(isZooming.value) {
			let center = calculateCenter(),
				currentDistance = calculateDistance(),
				scale = currentDistance / initialDistance.value;

			if(scale < 1) scale = 1;
			else if(scale > 4) scale = 4;

			let deltaX = (center.clientX - initialCenter.value.clientX) * scale;
			let deltaY = (center.clientY - initialCenter.value.clientY) * scale;

			return {
				transformOrigin: `${center.clientX - card.value.offsetLeft}px ${center.clientY - card.value.offsetTop}px`,
				transform: `translate(${deltaX}px, ${deltaY}px) scale(${scale})`
			};
		}else return {};
	});

	const setTransform = (x) => {
		if(x > draggingSize) x = draggingSize;
		else if(x < (draggingSize * -1)) x = draggingSize * -1;

		//if(Math.abs(x) < 100) x = 0;
		card.value.style.transform = `translate3d(${x}px, 0px, 0)`;

		var opacity = (isDeciding.value && Math.abs(moveX.value) >= draggingSize) ? 1 : .2;
		circleLike.value.style.opacity = opacity;
		circleReject.value.style.opacity = opacity;
	}

	const startDragging = ({ clientX, clientY, pointerId }) => {
		activePointers.value.set(pointerId, { clientX: clientX, clientY: clientY });
	}

	const dragging = ({ clientX, clientY, pointerId }) => {
		if(activePointers.value.has(pointerId)) activePointers.value.set(pointerId, { clientX: clientX, clientY: clientY });

		if(isDragging.value) {
			moveX.value = clientX - startX.value;
			moveY.value = clientY - startY.value;

			if(isDeciding.value == null && (Math.abs(moveX.value) > 10 || Math.abs(moveY.value) > 10)) {
				isDeciding.value = (Math.abs(moveX.value) > Math.abs(moveY.value));
			}

			if(isDeciding.value) setTransform(moveX.value);
		}
	}

	const stopDragging = ({ pointerId }) => {
		activePointers.value.delete(pointerId);

		if(isDragging.value) {
			if(isDeciding.value && Math.abs(moveX.value) >= draggingSize) emit('decision', (moveX.value > 0));
			setTransform(0);

			isDragging.value = false;
			isDeciding.value = null;
		}
	}

	watch(activePointers, (newValue) => {
		isDragging.value = (newValue.size == 1);
		isZooming.value = (newValue.size >= 2);
	}, { deep: true });

	watch(isDragging, (newValue, oldValue) => {
		if(newValue) {
			let first = activePointers.value.values().next().value;

			card.value.style.transition = 'transform 0ms';
			startX.value = first?.clientX ?? 0;
			startY.value = first?.clientY ?? 0;
		}else{
			card.value.style.transition = `transform ${transition}ms`;
			setTransform(0);
			isDeciding.value = null;
		}
	});

	watch(isZooming, (newValue, oldValue) => {
		emit('swiperChange', newValue);

		if(newValue) {
			image.value.style.transition = 'transform 0ms';

			initialCenter.value = calculateCenter();
			initialDistance.value = calculateDistance();
		}else{
			image.value.style.transition = `transform ${transition}ms`;
		}
	});

	return { card, image, circleLike, circleReject, isDragging, isDeciding, isZooming, draggingSize, transition, moveX, moveY, imageTransform, startDragging, dragging, stopDragging };
}