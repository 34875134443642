<template>
	<footer class="sticky-bottom vstack gap-4 align-items-center text-center background-filter z-2 pt-6 pb-7" :style="style?.style">
		<UserCardHeader :user="user" />
		<div class="hstack gap-3 justify-content-center">
			<router-link :to="{ name: 'user.search.index', params: { slug: user.slug }}" class="btn btn-md btn-outline-dark border-2 fw-bold px-10 text-uppercase rounded-pill" :style="style?.button">Buscar</router-link>
			<button type="button" class="btn btn-md btn-square btn-outline-dark border-2 fw-bold text-uppercase rounded-circle text-lg" :style="style?.button" @click="modalGroup.show" v-if="showGroups && userCurrent.groups.length > 1"><i class="bi bi-people"></i></button>
		</div>
		<div class="justify-content-center" v-if="showGroups && userCurrent.groups.length == 1">
			<GroupCommonButton :group="userCurrent.groups[0]" subtitle="Grupo" :style="style?.button" @select="modalGroup.show();" />
		</div>
		<AppCommonPowered v-if="$manifest?.microsite" />
	</footer>

	<UserModalGroup ref="modalGroup" v-if="showGroups" />
</template>

<script>
	import { ref, computed, inject } from 'vue';
	import store from '@/store';

	export default {
		props: {
			user: Object,
			style: Object
		},
		setup() {
			const viteAppName = import.meta.env.VITE_APP_NAME;
			const $manifest = inject('$manifest');
			const hasUser = computed(() => store.getters['auth/hasUser']);
			const userCurrent = computed(() => store.getters['auth/user']);
			const showGroups = computed(() => (hasUser.value && $manifest?.microsite && $manifest?.microsite?.settings?.groups && userCurrent.value.groups.length > 0));
			const modalGroup = ref(null);

			return { viteAppName, hasUser, userCurrent, showGroups, modalGroup };
		}
	}
</script>

<style scoped>
	.background-filter {
		background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0, rgba(255, 255, 255, 1) 4.5rem);
	}
</style>