<template>
	<LayoutFormInputFilter :id="`step-${currentStep}`" title="Estado de reserva" subtitle="Opcional" :active="step == currentStep" :completed="typeof modelValue == 'boolean'" :selected="optionDescription" @empty="empty" @setStep="setStep">
		<div class="col-6">
			<div class="form-item-checkable">
				<input class="form-item-check" type="radio" name="extras-reserved" :value="true" :checked="modelValue == true" @input="setValue" id="extras-reserved-true">
				<label class="form-item w-100" for="extras-reserved-true">
					<span class="form-item-click form-control text-muted p-4 w-100 vstack gap-1 justify-content-between lh-sm">
						Reservadas
					</span>
				</label>
			</div>
		</div>
		<div class="col-6">
			<div class="form-item-checkable">
				<input class="form-item-check" type="radio" name="extras-reserved" :value="false" :checked="modelValue == false" @input="setValue" id="extras-reserved-false">
				<label class="form-item w-100" for="extras-reserved-false">
					<span class="form-item-click form-control text-muted p-4 w-100 vstack gap-1 justify-content-between lh-sm">
						No reservadas
					</span>
				</label>
			</div>
		</div>
	</LayoutFormInputFilter>
</template>

<script>
	import { ref, computed, inject } from 'vue';
	import _ from 'lodash';

	export default {
		emits: ['update:modelValue', 'setStep'],
		props: {
			modelValue: Boolean,
			step: String
		},
		setup(props, { emit }) {
			const currentStep = 'extras.reserved';
			const $env = inject('$env');

			const setValue = (event) => emit('update:modelValue', (event.target.value === 'true'));
			const empty = () => emit('update:modelValue', null);
			const setStep = () => emit('setStep', currentStep);

			const optionDescription = computed(() => {
				if(props.modelValue === true) return 'Reservadas';
				else if(props.modelValue === false) return 'No reservadas';
				else return null;
			});

			return { currentStep, setValue, empty, setStep, optionDescription };
		}
	}
</script>