<template>
	<LayoutFormInputFilter :id="`step-${currentStep}`" title="Ambientes" subtitle="Opcional" :active="step == currentStep" :completed="modelValue != null" :selected="optionDescription" @empty="empty" @setStep="setStep">
		<div class="text-center" :class="{ 'col-6 col-sm-4': (index == 1), 'col-3 col-sm-2': (index > 1) }" v-for="(option, index) in options" v-bind:key="index">
			<div class="form-item-checkable">
				<input class="form-item-check" type="radio" name="project-view" :value="index" :checked="modelValue == index" @input="setValue" :id="index">
				<label class="form-item w-100" :for="index">
					<span class="form-item-click form-control text-muted p-2 w-100 vstack gap-1 justify-content-between lh-sm">
						{{ option }}
					</span>
				</label>
			</div>
		</div>
	</LayoutFormInputFilter>
</template>

<script>
	import { ref, computed } from 'vue';
	import _ from 'lodash';

	export default {
		emits: ['update:modelValue', 'setStep'],
		props: {
			modelValue: Number,
			step: String
		},
		setup(props, { emit }) {
			const currentStep = 'extras.rooms.total';

			const setValue = (event) => {
				let value = event.target.value;
				emit('update:modelValue', (value) ? Number(value) : null);
			}
			const empty = () => emit('update:modelValue', null);
			const setStep = () => emit('setStep', currentStep);

			const options = { 1: '1 ambiente', 2: '2+', 3: '3+', 4: '4+', 5: '5+' };
			const optionDescription = computed(() => {
				if(props.modelValue == 1) return `1 ambiente`;
				else if(props.modelValue >= 2) return `${props.modelValue} ambientes o más`;
				else return null;
			});

			return { currentStep, setValue, empty, setStep, options, optionDescription };
		}
	}
</script>