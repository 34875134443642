<template>
	<div class="hstack gap-3 align-items-center">
		<UserCommonImage :src="user?.avatar_url" size="md" rounded="3" :border="true" class="mx-auto" />
		<div class="flex-fill lh-sm text-limit">
			<div class="text-md text-truncate fw-bold">{{ user?.name }}</div>
			<div class="text-sm text-truncate text-muted hstack gap-1" v-if="subtitle">
				<div class="fw-bold">Cuenta</div>
				<div>·</div>
				<div>{{ subtitle }}</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			user: Object,
			subtitle: {
				type: String,
				default: null
			}
		}
	}
</script>