<template>
	<div class="list-group-item vstack px-0">
		<GroupCardMedium :group="group" :subtitle="subtitle" />
		<GroupCommonOption :group="group" />
	</div>
</template>

<script>
	import { computed } from 'vue';
	import moment from 'moment';

	export default {
		props: {
			group: Object
		},
		setup(props, { emit }) {
			const subtitle = computed(() => {
				return `Desde el ${moment(props.group?.pivot.created_at).format('DD/MM/YYYY')}`;
			});

			return { subtitle };
		}
	}
</script>