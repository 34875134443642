<template>
	<LayoutModalStandard ref="modal" title="Atención" id="auth-modal-google">
		<template #body>
			Google ha deshabilitado su función de inicio de sesión social en el explorador de Instagram. Esto significa que ya no podrán utilizar su cuenta de Google para iniciar sesión dentro de Instagram.<br />
			<br />
			La razón detrás de esta deshabilitación es una decisión tomada por Google y está fuera de nuestro control. Sin embargo, esto no significa que no puedan seguir utilizando el login de Google, para hacerlo, presione los tres puntos de la parte superior derecha y seleccione la opción de abrir en Chrome o Safari.<br />
			<br />
			Lamentamos los inconvenientes que esta decisión pueda causarles y esperamos que comprendan que esto está fuera de nuestro alcance. Si tienen alguna pregunta o inquietud, no duden en ponerse en contacto con nuestro equipo de soporte.
		</template>
	</LayoutModalStandard>
</template>

<script>
	import { ref } from 'vue';

	export default {
		setup() {
			const modal = ref(null);
			const show = () => modal.value.modal.show();

			return { modal, show };
		}
	}
</script>