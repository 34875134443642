import Repository from '../Repository';
import { useToast } from 'vue-toastification';

const resource = '/account/group';
const toast = useToast();

export default {
	index(group_id, params) {
		return Repository.get(`${resource}/${group_id}/publication`, { params: params });
	},
	categories(group_id) {
		return Repository.get(`${resource}/${group_id}/publication/categories`);
	}
}