<template>
	<LayoutFormInputGeneral :inputId="inputId" :title="title" :subtitle="subtitle" :validation="validation">
		<div>
			<div class="mb-1" v-if="modelValue"><i class="bi bi-check-lg text-success"></i> Seleccionada</div>
			<div class="mb-1" v-else><i class="bi bi-x text-danger"></i> Sin seleccionar</div>
			<label :for="inputId" class="btn btn-sm btn-neutral rounded-pill">
				<span>Seleccionar</span>
				<input type="file"
					accept="image/*"
					:id="inputId"
					@change="changeFile"
					class="visually-hidden">
			</label>
		</div>
	</LayoutFormInputGeneral>
</template>

<script>
	export default {
		emits: ['update:modelValue'],
		props: {
			modelValue: Object,
			title: String,
			subtitle: String,
			validation: Object
		},
		setup(props, { emit }) {
			const inputId = `input-${Math.random().toString(36).substr(2, 9)}`;

			const changeFile = (e) => {
				emit('update:modelValue', e.target.files[0]);
				e.target.value = null;
			}

			return { inputId, changeFile };
		}
	}
</script>