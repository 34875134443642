<template>
	<td width="50" class="py-4 px-0 text-end position-relative">
		<div class="dropstart">
			<button class="btn btn-md shadow-none border-0 text-md stretched-link" type="button" data-bs-toggle="dropdown" aria-expanded="false"><i class="bi bi-three-dots-vertical"></i></button>
			<div class="dropdown-menu px-0" v-if="$slots.options">
				<slot name="options" />
			</div>
		</div>
	</td>
</template>

<script>
	export default {
		props: {
			text: String
		}
	}
</script>

<style scoped>
	td {
		vertical-align: top;
	}
</style>