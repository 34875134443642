<template>
	<div class="vstack gap-3">
		<UserCardHome v-for="user in accounts" :key="user.id" :user="user" />
	</div>
</template>

<script>
	export default {
		props: {
			accounts: Array
		}
	}
</script>