<template>
	<LayoutTableCommonRow>
		<LayoutTableCommonPartImage :src="user.avatar_url" size="md" @select="options" />
		<LayoutTableCommonPartText :text="user.name" :subtext="(user.publications_count == 0) ? 'Sin publicaciones' : `${user.publications_count} ${(user.publications_count == 1) ? 'publicación' : 'publicaciones'}`" :colspan="(user.pivot.admin) ? 1 : 2" @select="options" />
		<LayoutTableCommonPartRaw class="text-end" @select="options" v-if="user.pivot.admin">
			<span class="badge rounded-pill bg-success text-xs">Admin.</span>
		</LayoutTableCommonPartRaw>
	</LayoutTableCommonRow>
</template>

<script>
	export default {
		emits: ['options'],
		props: {
			user: Object,
			edit: Boolean
		},
		setup(props, { emit }) {
			const options = () => {
				if(props.edit) emit('options', props.user);
			}

			return { options };
		}
	}
</script>