<template>
	<CommonFormInputAddress v-model="publication.address" title="Ingresar dirección" />
	<CommonFormInputSelect v-model="publication.operation" title="Tipo de operación" placeholder="Seleccionar tipo de operación" :options="$env.FIELDS.operations" optionsType="advance" :validation="validation.operation" />
	<CommonFormInputText v-model="publication.extras.code" title="Código" placeholder="Ingresar un código" :validation="validation.extras?.code" />
	<CommonFormInputNumber v-model="publication.extras.age" title="Antiguedad" subtitle="(Años)" placeholder="Ingrese la cantidad de años" :validation="validation.extras?.age" />
	<div class="hstack gap-5 align-items-start">
		<CommonFormInputNumber v-model="publication.extras.areas.total" step="0.01" title="Superficie total" placeholder="Ingrese los m2" :validation="validation.extras?.areas?.total" class="flex-fill" />
		<CommonFormInputNumber v-model="publication.extras.areas.covered" step="0.01" title="Cubierta" placeholder="Ingrese los m2" :validation="validation.extras?.areas?.covered" class="flex-fill" />
	</div>
	<div class="hstack gap-5 align-items-start">
		<CommonFormInputNumber v-model="publication.extras.rooms.total" title="Ambientes" placeholder="Ingrese la cantidad" :validation="validation.extras?.rooms?.total" class="flex-fill" />
		<CommonFormInputNumber v-model="publication.extras.rooms.bedrooms" title="Dormitorios" placeholder="Ingrese la cantidad" :validation="validation.extras?.rooms?.bedrooms" class="flex-fill" />
		<CommonFormInputNumber v-model="publication.extras.rooms.bathrooms" title="Baños" placeholder="Ingrese la cantidad" :validation="validation.extras?.rooms?.bathrooms" class="flex-fill" />
	</div>
	<CommonFormInputNumber v-model="publication.extras.parkings" title="Cocheras" placeholder="Ingrese la cantidad" :validation="validation.extras?.parkings" />
	<CommonFormInputBoolean v-model="publication.extras.credit" title="Apto crédito" :validation="validation.extras?.credit" />
	<CommonFormInputBoolean v-model="publication.extras.reserved" title="Reservada" :validation="validation.extras?.reserved" />
</template>

<script>
	export default {
		props: {
			publication: Object,
			validation: Object
		}
	}
</script>