<template>
	<LayoutTableCommonRow>
		<LayoutTableCommonPartImage :src="user.avatar_url" size="md" @select="select" />
		<LayoutTableCommonPartText :text="user.name" :subtext="dateDiff" @select="select" />
	</LayoutTableCommonRow>
</template>

<script>
	import { getCurrentInstance } from 'vue';
	import moment from 'moment';

	export default {
		props: {
			user: Object
		},
		setup(props) {
			const instance = getCurrentInstance();
			const dateDiff = moment(props.user.pivot.created_at).fromNow();
			const select = () => instance.proxy.$contactUser(props.user);

			return { dateDiff, select };
		}
	}
</script>