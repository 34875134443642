<template>
	<LayoutFormInputGeneral :inputId="inputId" :title="title" :subtitle="subtitle" :validation="validation">
		<div class="row g-3">
			<div class="col" v-for="(option, index) in options" v-bind:key="index">
				<div class="form-item-checkable">
					<input type="radio"
							:id="`${inputId}-${index}`"
							:name="inputId"
							:value="index"
							v-model="inputValue"
							@input="emitInput"
							class="form-item-check"
							:class="{'is-invalid': validation?.$error}">
					<label class="form-item w-100" :for="`${inputId}-${index}`">
						<span class="form-item-click form-control text-muted p-4 w-100 vstack gap-1 justify-content-between lh-xs">
							<i class="fi text-xl" :class="`fi-rr-${option.icon}`"></i>
							{{ option.description }}
						</span>
					</label>
				</div>
			</div>
		</div>
	</LayoutFormInputGeneral>
</template>

<script>
	import { ref } from 'vue';
	import _ from 'lodash';

	export default {
		emits: ['update:modelValue'],
		props: {
			modelValue: String,
			title: String,
			subtitle: String,
			validation: Object,
			options: {
				type: Object,
				default: {}
			},
			optionsType: {
				type: String,
				default: 'flat'
			},
			type: String
		},
		setup(props, { emit }) {
			const inputId = `input-${Math.random().toString(36).substr(2, 9)}`;
			const inputValue = ref(props.modelValue);

			const emitInput = (event) => {
				inputValue.value = event.target.value;
				emit('update:modelValue', inputValue.value);
			};

			return { inputId, inputValue, emitInput };
		}
	}
</script>