<template>
	<div>
		<div class="text-muted text-xs pb-1">Lugares buscados</div>
		<div class="text-md hstack gap-2 flex-wrap mb-1">
			<div class="badge text-bg-light fw-normal px-2" v-for="place in places" v-bind:key="place.id">
				{{ place.name }}
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			places: Array
		}
	}
</script>