<template>
	<LayoutOffcanvasStandard ref="offcanvas" id="auth-modal-identify" bodyClass="container-md vstack gap-3" footerClass="container-md hstack gap-2 justify-content-between" :title="(clean) ? title : null" :subtitle="(clean) ? subtitle : null">
		<template #body>
			<component ref="formRef" :is="component" :rules="rules" :email="email" @updateStep="updateStep" @updateEmail="updateEmail" @hide="hide" />
			<AppCommonPowered v-if="$manifest?.microsite && clean" />
		</template>
		<template #footer v-if="clean">
			<div class="hstack gap-2">
				<button class="btn btn-neutral rounded-pill" @click="logout" v-if="hasUser">Salir</button>
				<AuthButtonGoogle :submitting="formRef?.submitting" @setUser="updateStep('logged');" v-if="!['verify'].includes(step)" />
				<AuthButtonApple :submitting="formRef?.submitting" @setUser="updateStep('logged');" v-if="!['verify'].includes(step)" />
			</div>
			<button type="button" class="btn btn-primary rounded-pill" :disabled="formRef?.submitting" @click="formRef?.submit">Continuar</button>
		</template>
	</LayoutOffcanvasStandard>
</template>

<script>
	import { ref, computed, onMounted, getCurrentInstance } from 'vue';
	import router from '@/router';
	import store from '@/store';
	import AuthModalFormEmail from '@/components/Auth/Modal/Form/Email.vue';
	import AuthModalFormRecover from '@/components/Auth/Modal/Form/Recover.vue';
	import AuthModalFormSignup from '@/components/Auth/Modal/Form/Signup.vue';
	import AuthModalFormLogged from '@/components/Auth/Modal/Form/Logged.vue';
	import AuthModalFormVerify from '@/components/Auth/Modal/Form/Verify.vue';

	export default {
		setup() {
			const instance = getCurrentInstance();
			const hasUser = computed(() => store.getters['auth/hasUser']);
			const offcanvas = ref(null);
			const formRef = ref(null);
			const subtitle = ref(null);
			const step = ref(null);
			const rules = ref([]);
			const email = ref(null);
			const clean = computed(() => !['logged'].includes(step.value));

			const component = computed(() => {
				switch(step.value) {
					case 'email':
						return AuthModalFormEmail;
						break;
					case 'recover':
						return AuthModalFormRecover;
						break;
					case 'signup':
						return AuthModalFormSignup;
						break;
					case 'logged':
						return AuthModalFormLogged;
						break;
					case 'verify':
						return AuthModalFormVerify;
						break;
					default:
						return null;
						break;
				}
			});

			const title = computed(() => {
				switch(step.value) {
					case 'verify':
						return 'Verifica tu email';
						break;
					default:
						return 'Identificate';
						break;
				}
			});

			const hide = () => {
				offcanvas.value.offcanvas.hide();
			}

			const updateStep = (value) => {
				step.value = value;
			}

			const updateEmail = (value) => {
				email.value = value;
			}

			const identify = (defaults = {}) => {
				subtitle.value = defaults?.subtitle ?? 'Para utilizar la plataforma';
				step.value = defaults?.step ?? 'email';
				rules.value = defaults?.rules ?? [];
				email.value = null;
				offcanvas.value.offcanvas.show();
			}

			const logout = () => {
				hide();
				router.push({ name: 'auth.logout' });
			}

			onMounted(async () => {
				document.getElementById('auth-modal-identify').addEventListener('shown.bs.offcanvas', () => {
					document.querySelector('#auth-modal-identify input').focus();
				});

				if(instance) instance.appContext.config.globalProperties.$identify = identify;
			});

			return { offcanvas, formRef, hasUser, title, subtitle, step, rules, email, clean, component, hide, updateStep, updateEmail, logout };
		}
	}
</script>