<template>
	<div class="text-sm">
		Powered by <a href="https://choizr.com" target="_blank" class="link-primary">{{ viteAppName }}</a>
	</div>
</template>

<script>
	export default {
		setup() {
			const viteAppName = import.meta.env.VITE_APP_NAME;
			return { viteAppName };
		}
	}
</script>