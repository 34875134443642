<template>
	<LayoutOffcanvasStandard ref="offcanvas" title="Publicar búsqueda" subtitle="Podrán verla los integrantes del grupo" id="search-modal-public">
		<template #body>
			<form class="vstack gap-5" @submit.prevent="publish" autocomplete="off">
				<CommonFormInputTextarea v-model="form.description" title="Descripción" subtitle="Opcional" placeholder="Ingrese una descripción de la búsqueda" />
			</form>
		</template>
		<template #footer>
			<button type="button" class="btn btn-outline-danger rounded-pill me-auto" :disabled="submitting" @click="unpublish" v-if="form.public">Dejar de publicar</button>
			<button type="button" class="btn btn-primary rounded-pill" :disabled="submitting" @click="publish">Publicar</button>
		</template>
	</LayoutOffcanvasStandard>
</template>

<script>
	import { ref, reactive, onMounted } from 'vue';
	import store from '@/store';
	import router from '@/router';
	import _ from 'lodash';
	import useVuelidate from '@vuelidate/core';
	import { required, minLength, maxLength } from '@/helpers/i18n/validators';
	import composableForm from '@/composables/form';
	import SearchRepository from '@/repositories/SearchRepository';

	export default {
		props: {
			search: Object
		},
		setup(props) {
			const form = reactive({});
			const { submitting, catchError } = composableForm();

			const offcanvas = ref(null);
			const show = () => offcanvas.value.offcanvas.show();

			const validation = useVuelidate({
				title: {
					required,
					minLength: minLength(3),
					maxLength: maxLength(255)
				}
			}, form);

			const publish = async () => {
				form.public = true;
				submit();
			}

			const unpublish = async () => {
				form.public = false;
				submit();
			}

			const submit = async () => {
				if(submitting.value) return;
				if(!await validation.value.$validate()) return;

				submitting.value = true;

				await SearchRepository.publish(props.search.uuid, _.pick(form, ['public', 'description'])).then(async (response) => {
					store.dispatch('auth/upsertSearch', response.data);
					await offcanvas.value.offcanvas.hide();

					if(form.public) router.push({ name: 'account.group.search', params: { id: props.search.group_id }});
				}).catch((e) => {
					Object.assign(form, props.search);
					catchError(e, true);
				});

				submitting.value = false;
			}

			onMounted(() => {
				Object.assign(form, props.search);

				document.getElementById('search-modal-public').addEventListener('shown.bs.offcanvas', () => {
					document.querySelector('#search-modal-public textarea').focus();
				});
			});

			return { offcanvas, show, validation, form, submitting, publish, unpublish };
		}
	}
</script>