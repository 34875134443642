<template>
	<LayoutFormInputGeneral title="Respuestas de colaboradores">
		<div class="list-group list-group-flush">
			<div class="list-group-item hstack gap-3 align-items-baseline py-2" v-for="user in users" :key="user.id">
				<div>
					<div class="avatar rounded-circle">
						<img :src="user.avatar_url ?? assetsImgIconsAvatar" v-placeholder-image>
					</div>
				</div>
				<div class="flex-fill lh-sm">
					<div class="d-flex align-items-center justify-content-between">
						<div class="d-block h5 fw-bold">{{ user.name }}</div>
						<div class="rounded-pill bg-success py-1 px-2 hstack gap-1 text-white text-sm lh-xs" v-if="user.pivot.like === true">
							<div>Like</div>
							<i class="bi bi-heart-fill"></i>
						</div>
						<div class="rounded-pill bg-danger py-1 px-2 hstack gap-1 text-white text-sm lh-xs" v-else-if="user.pivot.like === false">
							<div>Rechazada</div>
							<i class="bi bi-x-lg"></i>
						</div>
					</div>
					<PublicationPartTags :tags="user.pivot.tags" v-if="user.pivot.tags" />
					<span class="d-block text-xs text-muted" v-if="user.pivot.comment" v-html="`'${user.pivot.comment}'`"></span>
				</div>
			</div>
		</div>
	</LayoutFormInputGeneral>
</template>

<script>
	import assetsImgIconsAvatar from '@/assets/img/icons/avatar.jpg';

	export default {
		props: {
			users: Array
		},
		setup() {
			return { assetsImgIconsAvatar };
		}
	}
</script>