<template>
	<CommonFormInputText v-model="publication.extras.code" title="Código" placeholder="Ingresar un código" :validation="validation.extras?.code" />
	<CommonFormInputText v-model="publication.extras.brand" title="Marca" placeholder="Ingresar una marca" :validation="validation.extras?.brand" />
	<CommonFormInputTags v-model="publication.extras.tags" title="Tags" placeholder="Ingresar los tags" :validation="validation.extras?.tags" />
	<CommonFormInputSelect v-model="publication.extras.last_season" title="Temporada" placeholder="Seleccionar la temporada" :options="$env.FIELDS.last_season" optionsType="advance" :validation="validation.extras?.last_season" />
	<CommonFormInputSelect v-model="publication.extras.gender" title="Género" placeholder="Seleccionar el género" :options="$env.FIELDS.genders" optionsType="advance" :validation="validation.extras?.gender" />
	<CommonFormInputSelect v-model="publication.extras.age_group" title="Edad" placeholder="Seleccionar la edad" :options="$env.FIELDS.age_groups" optionsType="flat" :validation="validation.extras?.age_group" />
</template>

<script>
	export default {
		props: {
			publication: Object,
			validation: Object
		}
	}
</script>