<template>
	<transition name="fade-fast" mode="out-in">
		<LayoutLoading text="Cargando resultados" v-if="!search" />
		<LayoutClean class="h-100 vstack" v-else>
			<main class="container-md px-0 flex-fill position-relative overflow-hidden">
				<swiper
					:direction="'vertical'"
					:slidesPerView="1"
					:spaceBetween="10"
					:mousewheel="true"
					:virtual="true"
					:modules="modules"
					class="mySwiper"
					@swiper="setSwiperRef"
					@slideChange="slideChange"
				>
					<swiper-slide v-slot="{ isActive }" v-for="(publication, index) in publications" :key="index" :virtualIndex="index">
						<PublicationCardMain :publication="publication" :isActive="activeIndex == index" @detail="detail" @decision="decision" @setViewed="setViewed" @swiperChange="swiperChange" />
					</swiper-slide>
					<swiper-slide v-slot="{ isActive }" v-if="!hasMore">
						<SearchCardFinish :search="search" :count="count" @share="modalOption.share();" @send="modalOption.send();" @publish="modalOption.publish();" @finish="modalOption.finish();" />
					</swiper-slide>
				</swiper>
			</main>
			<footer class="footer mt-auto py-4 border-top border-2 border-dark">
				<div class="container-md">
					<SearchPartAction :search="search" @option="modalOption.show();" @decision="decision" @undo="undo" @map="map" :disabled="actionDisableds" :count="count" />
				</div>
			</footer>

			<SearchModalOption ref="modalOption" :search="search" :count="count" :vieweds="vieweds" @display="display" @guide="guideSearchResult.show();" />
			<PublicationModalDetail ref="modalDetail" :publication="publication" />
			<GuideSearchResult ref="guideSearchResult" />
		</LayoutClean>
	</transition>
</template>

<script>
	import { ref, computed, watch, onMounted, onUnmounted, inject } from 'vue';
	import { useRoute } from 'vue-router';
	import router from '@/router';
	import store from '@/store';
	import _ from 'lodash';
	import { useToast } from 'vue-toastification';
	import { Swiper, SwiperSlide } from 'swiper/vue';
	import { Mousewheel, Virtual } from 'swiper/modules';
	import CommonNotificationSuperLike from '@/components/Common/Notification/SuperLike.vue';
	import composableForm from '@/composables/form';
	import SearchRepository from '@/repositories/SearchRepository';

	export default {
		components: { Swiper, SwiperSlide },
		setup() {
			const { catchError } = composableForm();
			const $env = inject('$env');
			const route = useRoute();
			const toast = useToast();
			const modalOption = ref(null);
			const modalDetail = ref(null);
			const guideSearchResult = ref(null);
			const hasUser = computed(() => store.getters['auth/hasUser']);
			const search = computed(() => _.find(store.getters['auth/searches'], { 'uuid': route.params.uuid }));
			const swiper = ref(null);
			const count = ref(0);
			const activeIndex = ref(0);
			const lastDecisions = ref([]);
			const publicationsLoaded = ref(false);
			const publications = ref([]);
			const publicationDisplaying = ref(false);
			const publication = ref(null);
			const undoProcessing = ref(false);
			const vieweds = ref([]);

			const setViewed = (id) => {
				if(!vieweds.value.includes(id)) vieweds.value.push(id);
			}

			const actionDisableds = computed(() => {
				let data = [];
				if(lastDecisions.value.length == 0 || undoProcessing.value) data.push('undo');
				if(activeIndex.value == publications.value.length) data.push('decision');

				return data;
			});

			const detail = (data) => {
				publication.value = data;
				modalDetail.value.show();
			}

			const join = () => {
				if(hasUser.value) SearchRepository.join(route.params.uuid).then((response) => {
					store.dispatch('auth/upsertSearch', response.data);
				}).catch(() => console.error('Join search error...'));
			}

			const decision = (like, is_super = false) => {
				if(!hasUser.value) return modalOption.value.login();

				let index = activeIndex.value,
					selected = _.cloneDeep(publications.value[index]),
					likePrev = publications.value[index].like ?? null,
					is_superPrev = publications.value[index].is_super ?? null;

				publications.value[index].like = like;
				publications.value[index].is_super = is_super;

				SearchRepository.decision(route.params.uuid, { publication_id: selected.id, like: like, is_super: is_super }).then((response) => {
					if(is_super) {
						toast.success({
							component: CommonNotificationSuperLike,
							props: {
								id: selected.id
							},
							listeners: {
								undo: undo
							}
						}, { timeout: 7000 });
						//toast.success(`¡Super Like!, enviaremos tu información a los administradores de la propiedad para que puedan contactarte!.`, { icon: 'bi bi-heart-fill', timeout: 7000 });
					}
					if(response.data.coincidences.exists) toast.success(`Tu like a ${selected.title} coincide con ${response.data.coincidences.users.join(', ')}`, { timeout: 7000 });
					getCount();
				}).catch((e) => {
					publications.value[index].like = likePrev;
					publications.value[index].is_super = is_super;
					catchError(e, true);
				});

				if(_.last(lastDecisions.value) != activeIndex.value) lastDecisions.value.push(activeIndex.value);

				setTimeout(() => swiper.value.slideNext(), 500);
			}

			const undo = async (id = null) => {
				if(!hasUser.value) {
					modalOption.value.login();
					return;
				}

				let index = (id) ? _.findIndex(publications.value, { id: id }) : _.last(lastDecisions.value);

				if(index != undefined) {
					undoProcessing.value = true;
					swiper.value.slideTo(index);

					let likePrev = publications.value[index].like ?? null,
						is_superPrev = publications.value[index].is_super ?? null;

					publications.value[index].like = null;
					publications.value[index].is_super = null;

					await SearchRepository.undo(route.params.uuid, { publication_id: publications.value[index].id }).then((response) => {
						_.remove(lastDecisions.value, (data) => data === index);
						getCount();
					}).catch((e) => {
						publications.value[index].like = likePrev;
						publications.value[index].is_super = is_superPrev;
						catchError(e, true);
					});

					undoProcessing.value = false;
				}
			}

			const map = () => {
				modalOption.value.modalMap.show();
			}

			const getCount = () => {
				if(route.params?.uuid) SearchRepository.count(route.params.uuid).then((response) => {
					count.value = response.data.count;
				}).catch((e) => catchError(e, true));
			}

			const getPublications = async () => {
				publicationsLoaded.value = false;

				await SearchRepository.results(route.params.uuid, { except: _.map(publications.value, 'id') }).then((response) => {
					publicationsLoaded.value = true;

					if(response.data.length > 0) {
						remainingMin.value = Math.round(response.data.length / 3);
						publications.value = _.concat(publications.value, response.data);
					}

					if(response.data.length < $env.MODEL.search.results) hasMore.value = false;
				}).catch((e) => {
					publicationsLoaded.value = false;
					catchError(e, true);
				});
			}

			const getPublication = async (id) => {
				await SearchRepository.result(route.params.uuid, { id: id }).then((response) => {
					let firstPart = _.slice(publications.value, 0, (activeIndex.value + 1)),
						secondPart = _.slice(publications.value, (activeIndex.value + 1));

					publications.value = _.concat(firstPart, response.data, secondPart);
					swiper.value.slideTo(activeIndex.value + 1);
				}).catch((e) => catchError(e, true));
			}

			const display = async (id) => {
				if(publicationDisplaying.value) return;
				publicationDisplaying.value = true;

				let index = _.findIndex(publications.value, { id: id });
				if(index != -1) swiper.value.slideTo(index);
				else await getPublication(id);

				modalOption.value.modalMap.hide();
				publicationDisplaying.value = false;
			}

			const setSwiperRef = (ref) => {
				swiper.value = ref;
			}

			const swiperChange = (disable) => {
				if(disable) {
					swiper.value.slideTo(activeIndex.value);
					swiper.value.disable();
				}else swiper.value.enable();
			}

			const slideChange = () => {
				activeIndex.value = swiper.value.activeIndex;
			}

			const getSearch = async () => {
				await SearchRepository.show(route.params.uuid).then((response) => {
					store.dispatch('auth/upsertSearch', response.data);
					if(hasUser.value) getCount();
				}).catch((e) => {
					catchError(e, true);
					router.push({ name: 'home' });
				});
			}

			const remainingMin = ref(null);
			const hasMore = ref(true);
			watch(activeIndex, (newValue) => {
				if((newValue + 1) > (publications.value.length - remainingMin.value) && publicationsLoaded.value && hasMore.value) getPublications();
			});

			const guideTimeout = ref(null);
			onMounted(async () => {
				document.querySelector('body').classList.add('overflow-hidden');

				await getSearch();
				if(search.value?.id) await getPublications();
				if(publications.value.length > 0) guideTimeout.value = setTimeout(() => guideSearchResult.value.autostart(), 5000);
			});

			onUnmounted(() => {
				if(guideTimeout.value) clearTimeout(guideTimeout.value);
				document.querySelector('body').classList.remove('overflow-hidden');
			});

			return { modules: [ Mousewheel, Virtual ], modalOption, modalDetail, guideSearchResult, search, getSearch, hasMore, publications, publication, vieweds, setViewed, actionDisableds, count, activeIndex, detail, decision, undo, map, display, setSwiperRef, swiperChange, slideChange };
		}
	}
</script>

<style>
	.swiper {
		width: 100%;
		height: 100%;
	}

	.swiper-slide {
		/* Center slide text vertically */
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.swiper-slide img {
		display: block;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
</style>
