<template>
	<LayoutFormInputFilter title="Ordenar por" subtitle="Opcional" :active="true" :selected="optionDescription" @empty="empty">
		<select :value="modelValue" @input="setValue" class="form-select">
			<option v-for="(text, index) in $env.MODEL.search.orders" v-bind:key="index" :value="index">{{ text }}</option>
		</select>
	</LayoutFormInputFilter>
</template>

<script>
	import { ref, computed, inject } from 'vue';
	import _ from 'lodash';

	export default {
		emits: ['update:modelValue'],
		props: {
			modelValue: String
		},
		setup(props, { emit }) {
			const $env = inject('$env');

			const setValue = (event) => emit('update:modelValue', event.target.value);
			const empty = () => emit('update:modelValue', null);

			const optionDescription = computed(() => {
				if(!props.modelValue) return null;
				else return $env.MODEL.search.orders[props.modelValue];
			});

			return { setValue, empty, optionDescription };
		}
	}
</script>