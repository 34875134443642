<template>
	<LayoutTableCommonRow>
		<LayoutTableCommonPartImage :src="microsite.logo_square_url" size="md" />
		<LayoutTableCommonPartText :text="microsite.name" :subtext="microsite.host" :header="dateFormatted" />
		<LayoutTableCommonPartMenu>
			<template #options>
				<router-link :to="{ name: 'admin.microsite.edit', params: { id: microsite.id }}" class="dropdown-item rounded-0">Editar</router-link>
				<a href="javascript:void(0)" class="dropdown-item rounded-0" @click="remove">Eliminar</a>
			</template>
		</LayoutTableCommonPartMenu>
	</LayoutTableCommonRow>
</template>

<script>
	import { computed } from 'vue';
	import moment from 'moment';

	export default {
		emits: ['remove'],
		props: {
			microsite: Object
		},
		setup(props, { emit }) {
			const remove = () => emit('remove', props.microsite);
			const dateFormatted = computed(() => moment(props.microsite.created_at).format('DD/MM/YYYY'));

			return { remove, dateFormatted };
		}
	}
</script>