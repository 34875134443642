<template>
	<LayoutFormInputGeneral :inputId="inputId" :title="title" :subtitle="subtitle" :info="info" :validation="validation">
		<select :id="inputId" :value="modelValue" :disabled="disabled" @input="emitInput" class="form-select" :class="{'is-invalid': validation?.$error}" aria-label="Default select example">
			<option value="" selected v-if="placeholder">{{ placeholder }}</option>
			<option v-for="(text, index) in optionsFiltereds" v-bind:key="index" :value="index">{{ text }}</option>
		</select>
	</LayoutFormInputGeneral>
</template>

<script>
	import { computed } from 'vue';
	import _ from 'lodash';

	export default {
		emits: ['update:modelValue'],
		props: {
			modelValue: String,
			title: String,
			subtitle: String,
			info: String,
			placeholder: String,
			validation: Object,
			options: {
				type: Object,
				default: {}
			},
			optionsType: {
				type: String,
				default: 'flat'
			},
			disabled: {
				type: Boolean,
				default: false
			}
		},
		setup(props, { emit }) {
			const inputId = `input-${Math.random().toString(36).substr(2, 9)}`;
			const optionsFiltereds = computed(() => {
				switch(props.optionsType) {
					case 'advance':
						return _.mapValues(props.options, 'description');
						break;
					default:
						return props.options;
						break;
				}
			});

			const emitInput = (event) => {
				emit('update:modelValue', event.target.value);
			};

			return { inputId, optionsFiltereds, emitInput };
		}
	}
</script>