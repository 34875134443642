<template>
	<LayoutOffcanvasStandard ref="offcanvas" title="Instalar aplicación" id="app-permission-install">
		<template #body>
			<div class="vstack">
				<h4 class="fw-bolder text-dark">¿Te gusta nuestra plataforma?</h4>
				<p class="text-muted lh-sm mt-1">Instalá nuestra aplicación progresiva y obtené una mejor experiencia en <b>{{ $manifest?.name }}</b>.</p>
				<template v-if="isIos">
					<div class="mt-4">
						<h4 class="fw-bolder text-dark">Amplía las características de <b>{{ $manifest?.name }}</b>.</h4>
						<p class="text-muted lh-sm mt-1">Al instalar la aplicación, podrás recibir notificaciones en tu dispositivo.</p>
					</div>
					<div class="mt-4">
						<h4 class="fw-bolder text-dark">Para instalar la aplicación en iOS:</h4>
						<ul class="list-group list-group-flush mt-1">
							<li class="list-group-item border-0 py-1">
								<i class="bi bi-box-arrow-up pe-2"></i>1. Click en el botón <b>compartir</b>.
							</li>
							<li class="list-group-item border-0 py-1">
								<i class="bi bi-plus-square pe-2"></i>2. Click en <b>agregar a inicio</b>.
							</li>
						</ul>
					</div>
				</template>
			</div>
			<AppCommonPowered v-if="$manifest?.microsite" />
		</template>
		<template #footer>
			<button type="button" class="btn btn-neutral rounded-pill" @click="dismiss">Ahora no</button>
			<button type="button" class="btn btn-primary rounded-pill" @click="accept" v-if="deferredPrompt">Instalar</button>
			<button type="button" class="btn btn-primary rounded-pill" data-bs-dismiss="offcanvas" v-else>Entendido</button>
		</template>
	</LayoutOffcanvasStandard>
</template>

<script>
	import { ref, computed, onMounted, onBeforeUnmount } from 'vue';
	import { VueCookieNext } from 'vue-cookie-next';

	export default {
		setup() {
			const isIos = /iphone|ipad|ipod/.test(window.navigator.userAgent.toLowerCase());
			const deferredPrompt = ref(null);
			const timeout = ref(null);

			const installed = computed(() => {
				if(isIos) return window.navigator.standalone;
				else return window.matchMedia('(display-mode: standalone)').matches;
			});

			const canInstall = computed(() => {
				return (deferredPrompt.value || isIos);
			});

			const offcanvas = ref(null);
			const permissionInstallShow = () => offcanvas.value.offcanvas.show();
			const permissionInstallHide = () => offcanvas.value.offcanvas.hide();

			window.addEventListener('appinstalled', () => {
				deferredPrompt.value = null;
			});

			window.addEventListener('beforeinstallprompt', (e) => {
				e.preventDefault();
				deferredPrompt.value = e;
			});

			const dismiss = async () => {
				VueCookieNext.setCookie('permission-install', null, { expire: '10d' });
				deferredPrompt.value = null;
				permissionInstallHide();
			}

			const accept = async () => {
				permissionInstallHide();
				deferredPrompt.value.prompt();
			}

			const previousShow = () => {
				if(document.querySelector('.modal.show') || document.querySelector('.offcanvas.show')) timeout.value = setTimeout(previousShow, 5000);
				else permissionInstallShow();
			}

			onMounted(() => {
				setTimeout(() => {
					if(!installed.value && canInstall.value && !VueCookieNext.isCookieAvailable('permission-install'))
						previousShow();
				}, 60000);
			});

			onBeforeUnmount(() => {
				if(timeout.value) clearTimeout(timeout.value);
			});

			return { isIos, offcanvas, deferredPrompt, dismiss, accept };
		}
	}
</script>