<template>
	<LayoutClean class="vstack justify-content-center">
		<div class="container-sm py-10">
			<div class="vstack gap-4 text-center">
				<div>
					<router-link :to="{ name: 'home' }" class="d-inline-block">
						<img :src="logo" class="h-rem-12" alt="logo">
					</router-link>
				</div>
				<div class="ls-tight lh-sm text-md text-center px-5 px-md-10">Página no encontrada :(</div>
				<div>
					<router-link :to="{ name: 'home' }" class="btn btn-primary rounded-pill">Ir al inicio</router-link>
				</div>
			</div>
		</div>
	</LayoutClean>
</template>

<script>
	import { computed, inject } from 'vue';
	import assetsImgLogoComplete from '@/assets/img/logo/complete.png';

	export default {
		props: {
			text: String
		},
		setup(props) {
			const $manifest = inject('$manifest');
			const logo = computed(() => {
				if($manifest?.microsite?.logo?.secondary) return $manifest.microsite.logo.secondary;
				else return assetsImgLogoComplete;
			});

			return { logo };
		}
	}
</script>