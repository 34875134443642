<template>
	<LayoutFormInputGeneral :inputId="inputId" :title="title" :subtitle="subtitle" :validation="validation">
		<code><pre>{{ JSON.stringify(modelValue, null, 2) }}</pre></code>
	</LayoutFormInputGeneral>
</template>

<script>
	import { ref } from 'vue';

	export default {
		props: {
			modelValue: Object,
			title: String,
			subtitle: String,
			validation: Object
		},
		setup() {
			const inputId = `input-${Math.random().toString(36).substr(2, 9)}`;

			return { inputId };
		}
	}
</script>