<template>
	<div class="flex-fill hstack gap-2 align-items-center justify-content-between">
		<div class="hstack gap-2 position-relative text-limit">
			<UserCommonImage :src="user?.avatar_url" size="xs" class="mx-auto" />
			<div class="flex-fill lh-xs text-limit">
				<a href="javascript:void(0);" class="d-block text-dark text-sm text-break text-truncate stretched-link" @click="select">{{ user?.name }}</a>
				<div class="text-muted text-truncate" :class="subtitleClass" v-if="subtitle">{{ subtitle }}</div>
			</div>
		</div>
		<UserCardPartContact :user="user" size="sm" v-if="contactInline" />
	</div>
</template>

<script>
	import { getCurrentInstance } from 'vue';

	export default {
		props: {
			user: Object,
			subtitleClass: String,
			subtitle: {
				type: String,
				default: null
			},
			contactModal: {
				type: Boolean,
				default: false
			},
			contactInline: {
				type: Boolean,
				default: false
			}
		},
		setup(props) {
			const instance = getCurrentInstance();

			const select = () => {
				if(props.contactModal) instance.proxy.$contactUser(props.user);
			}

			return { select };
		}
	}
</script>