<template>
	<LayoutOffcanvasStandard ref="offcanvas" title="Buscar en grupos" id="search-modal-groups" footerClass="container-md hstack gap-3">
		<template #body>
			<div class="list-group list-group-flush gap-5">
				<div v-for="group in groups" v-bind:key="group.id" class="list-group-item hstack gap-5 border-0 p-0">
					<div>
						<AccountGroupCardSmall :group="group" :subtitle="dateDiff(group.pivot.created_at)" />
					</div>
					<a href="javascript:void(0)" @click="routerPush({ name: 'group.search.index', params: { id: group.id }})" class="link-dark stretched-link ms-auto text-end align-self-center">
						<i class="bi bi-chevron-right"></i>
					</a>
				</div>
			</div>
		</template>
		<template #footer>
			<div class="hstack gap-4 align-items-center">
				<a href="javascript:void(0)" @click="routerPush({ name: 'account.group.index' })" class="link-primary">Ver listado completo</a>
			</div>
		</template>
	</LayoutOffcanvasStandard>
</template>

<script>
	import { ref } from 'vue';
	import router from '@/router';
	import moment from 'moment';

	export default {
		props: {
			groups: Array
		},
		setup(props) {
			const offcanvas = ref(null);
			const show = () => offcanvas.value.offcanvas.show();
			const dateDiff = (date) => moment(date).fromNow();

			const routerPush = (options) => {
				document.getElementById('search-modal-groups').addEventListener('hidden.bs.offcanvas', () => router.push(options));
				offcanvas.value.offcanvas.hide();
			}

			return { offcanvas, show, dateDiff, routerPush };
		}
	}
</script>