<template>
	<LayoutFormInputGeneral :inputId="inputId" :title="title" :subtitle="subtitle" :info="info" :validation="validation">
		<div class="input-group">
			<input type="text"
					:id="inputId"
					v-model="currentValue"
					@keydown.enter.prevent="add"
					class="form-control flex-fill"
					:class="{'is-invalid': validation?.$error}"
					:placeholder="placeholder"
					v-focus="focus">
			<button type="button" class="btn btn-neutral" @click="add" :disabled="!currentValue">Agregar</button>
		</div>
		<div class="mt-1" v-if="currentValue">
			<CommonMessageInfo class="mt-2" info="Para guardar la palabra clave, presiona la tecla <b>Enter</b> o el botón <b>Agregar</b>." />
		</div>
		<div class="text-md hstack gap-2 flex-wrap mt-2">
			<button type="button" class="btn btn-xs btn-primary" v-for="(tag, index) in inputValue" v-bind:key="index" @click="remove(index)">{{ tag }}<i class="bi bi-trash-fill text-xs ms-2"></i></button>
		</div>
	</LayoutFormInputGeneral>
</template>

<script>
	import { ref, defineProps, defineEmits } from 'vue';
	import _ from 'lodash';

	export default {
		props: {
			modelValue: {
				type: Array,
				default: []
			},
			title: String,
			subtitle: String,
			info: String,
			placeholder: String,
			validation: Object,
			focus: {
				type: Boolean,
				default: false
			}
		},
		setup(props, { emit }) {
			const inputId = `input-${Math.random().toString(36).substr(2, 9)}`;
			const inputValue = ref(props.modelValue);
			const currentValue = ref(null);

			const emitInput = () => {
				emit('update:modelValue', inputValue.value);
			};

			const add = () => {
				if(currentValue.value) {
					if(!inputValue.value.includes(currentValue.value))
						inputValue.value.push(currentValue.value);

					currentValue.value = null;
					emitInput();
				}
			};

			const remove = (index) => {
				_.pullAt(inputValue.value, index);
				emitInput();
			};

			return { inputId, inputValue, currentValue, add, remove };
		}
	}
</script>