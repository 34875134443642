<template>
	<div class="hstack gap-4 mt-2 lh-xs">
		<div class="btn-group">
			<router-link :to="{ name: 'account.group.publication', params: { id: group.id }}" class="btn btn-xs btn-outline-dark rounded-start-pill px-2 text-xs lh-1">Cartera Interna</router-link>
			<router-link :to="accountPublicationCreateRoute" class="btn btn-xs btn-dark px-2 text-xs lh-1 rounded-end-pill"><i class="bi bi-plus-lg"></i></router-link>
		</div>
		<div class="vr my-auto" style="height: 10px;"></div>
		<div class="btn-group">
			<router-link :to="{ name: 'account.group.search', params: { id: group.id }}" class="btn btn-xs btn-outline-dark rounded-start-pill px-2 text-xs lh-1">Búsquedas activas</router-link>
			<router-link :to="{ name: 'group.search.index', params: { id: group.id }}" class="btn btn-xs btn-dark px-2 text-xs lh-1 rounded-end-pill"><i class="bi bi-plus-lg"></i></router-link>
		</div>
	</div>
</template>

<script>
	import { computed } from 'vue';

	export default {
		props: {
			group: Object
		},
		setup(props) {
			const accountPublicationCreateRoute = computed(() => {
				let route = { name: 'account.publication.create' };
				if(props.group?.public === false) route.query = { public: props.group.public };

				return route;
			});

			return { accountPublicationCreateRoute };
		}
	}
</script>