<template>
	<transition name="fade-slow" mode="out-in">
		<div class="position-relative portrait overflow-hidden w-100 border border-2 border-dark rounded-4" v-if="ready">
			<img :src="url" v-placeholder-image class="object-cover position-absolute top-50 start-50 translate-middle">
		</div>
	</transition>
</template>

<script>
	import { ref, onMounted } from 'vue';

	export default {
		props: {
			url: String
		},
		setup(props) {
			const ready = ref(false);

			onMounted(() => {
				var loadImage = new Image();
				loadImage.src = props.url;
				loadImage.onload = function() { ready.value = true; };
			});

			return { ready };
		}
	}
</script>