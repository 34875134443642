<template>
	<div class="text-sm">
		<div class="text-muted text-xs">Filtro aplicado</div>
		<div class="lh-sm" v-html="list"></div>
	</div>
</template>

<script>
	import { computed, inject } from 'vue';
	import _ from 'lodash';

	export default {
		props: {
			filter: Object
		},
		setup(props) {
			const $env = inject('$env');
			const list = computed(() => {
				let numberFormat = new Intl.NumberFormat('es-ES'),
					array = [];

				if(props.filter?.operation && props.filter?.category) array.push(`${$env.FIELDS.operations[props.filter.operation].description} ${$env.FIELDS.categories[props.filter.category].description}`);
				else if(props.filter?.operation) array.push($env.FIELDS.operations[props.filter.operation].description);
				else if(props.filter?.category) array.push($env.FIELDS.categories[props.filter.category].description);

				if(props.filter?.amount?.min || props.filter?.amount?.max) {
					let extra = [];

					if(props.filter?.amount?.min && props.filter?.amount?.max) extra.push(`de ${numberFormat.format(props.filter.amount.min)} a ${numberFormat.format(props.filter.amount.max)}`);
					else if(props.filter?.amount?.min) extra.push(`desde ${numberFormat.format(props.filter.amount.min)}`);
					else if(props.filter?.amount?.max) extra.push(`hasta ${numberFormat.format(props.filter.amount.max)}`);

					array.push(`${extra.join(' ')}${(props.filter?.currency) ? ' '+props.filter.currency : ''}`);
				}

				if(props.filter.extras?.areas?.total && (props.filter.extras.areas.total.min || props.filter.extras.areas.total.max)) {
					let extra = [];

					if(props.filter.extras.areas.total.min && props.filter.extras.areas.total.max) extra.push(`de ${props.filter.extras.areas.total.min} a ${props.filter.extras.areas.total.max}`);
					else if(props.filter.extras.areas.total.min) extra.push(`desde ${props.filter.extras.areas.total.min}`);
					else if(props.filter.extras.areas.total.max) extra.push(`hasta ${props.filter.extras.areas.total.max}`);

					array.push(`${extra.join(' ')} m2 totales`);
				}

				if(props.filter.extras?.areas?.covered && (props.filter.extras.areas.covered.min || props.filter.extras.areas.covered.max)) {
					let extra = [];

					if(props.filter.extras.areas.covered.min && props.filter.extras.areas.covered.max) extra.push(`de ${props.filter.extras.areas.covered.min} a ${props.filter.extras.areas.covered.max}`);
					else if(props.filter.extras.areas.covered.min) extra.push(`desde ${props.filter.extras.areas.covered.min}`);
					else if(props.filter.extras.areas.covered.max) extra.push(`hasta ${props.filter.extras.areas.covered.max}`);

					array.push(`${extra.join(' ')} m2 cubiertos`);
				}

				if(props.filter.extras?.credit) array.push(`apto crédito`);

				if(props.filter.extras?.rooms?.total) array.push(`+${props.filter.extras.rooms.total} ambientes`);
				if(props.filter.extras?.rooms?.bedrooms) array.push(`+${props.filter.extras.rooms.bedrooms} dormitorios`);
				if(props.filter.extras?.rooms?.bathrooms) array.push(`+${props.filter.extras.rooms.bathrooms} baños`);
				if(props.filter.extras?.parkings) array.push(`+${props.filter.extras.parkings} cocheras`);
				if(props.filter.extras?.brand) array.push(props.filter.extras.brand);
				if(props.filter.extras?.last_season) array.push(`última temporada`);
				if((props.filter.extras?.tags ?? []).length > 0) array.push(`tags: ${props.filter.extras.tags.join(', ')}`);

				array = _.map(array, (text) => `<span class="d-inline-block">${text}</span>`);
				return array.join(', ');
			});

			return { list };
		}
	}
</script>