<template>
	<div class="vstack gap-3">
		<GroupCardHome v-for="group in groups" :key="group.id" :group="group" />
	</div>
</template>

<script>
	export default {
		props: {
			groups: Array
		}
	}
</script>