<template>
	<div class="avatar bg-white rounded-circle" :class="classes">
		<img :src="src" v-placeholder-image class="object-cover" alt="Avatar">
	</div>
</template>

<script>
	export default {
		props: {
			src: String,
			imageClass: String,
			size: {
				type: String,
				default: 'md'
			}
		},
		setup(props) {
			const classes = [
				`avatar-${props.size}`,
				props.imageClass
			];

			return { classes };
		}
	}
</script>