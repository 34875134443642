<template>
	<LayoutMain>
		<LayoutHeaderSection>
			<template #title>
				<LayoutTextSection text="Editar" :breadcrumb="true" />
			</template>
		</LayoutHeaderSection>
		<main class="container-md pt-8 vstack gap-10">
			<CommonFormWrapper submitText="Guardar" :submitting="submitting" :errors="validationExternal" @submit="submit">
				<template #body>
					<CommonFormInputText v-model="form.name" title="Nombre" subtitle="Obligatorio" placeholder="Ingresa tu nombre" :focus="true" :validation="validation.name" />
					<CommonFormInputPhone v-model="form.phone" title="Teléfono" placeholder="Ingresa tu número telefónico" :validation="validation.phone" />
					<LayoutFormInputGeneral title="Medios de contacto habilitados" subtitle="Obligatorio">
						<div class="vstack gap-2">
							<div class="form-check lh-sm">
								<input type="checkbox" id="contact_types.email" value="email" v-model="form.contact_types" class="form-check-input">
								<label class="form-check-label ps-0 vstack" for="contact_types.email">
									<b>Correo electrónico</b>
									<small>Mostrá tu correo electrónico a otros usuarios</small>
								</label>
							</div>
							<div class="form-check lh-sm" v-if="form.phone">
								<input type="checkbox" id="contact_types.phone" value="phone" v-model="form.contact_types" class="form-check-input">
								<label class="form-check-label ps-0 vstack" for="contact_types.phone">
									<b>Llamadas telefónicas</b>
									<small>Mostrá tu teléfono a otros usuarios</small>
								</label>
							</div>
							<div class="form-check lh-sm" v-if="form.phone">
								<input type="checkbox" id="contact_types.whatsapp" value="whatsapp" v-model="form.contact_types" class="form-check-input">
								<label class="form-check-label ps-0 vstack" for="contact_types.whatsapp">
									<b>Whatsapp</b>
									<small>Mostrará tu contacto de Whatsapp a otros usuarios</small>
								</label>
							</div>
						</div>
					</LayoutFormInputGeneral>
					<LayoutFormInputGeneral title="Tipos de notificaciones">
						<div class="vstack gap-2">
							<div class="form-check lh-sm" v-for="(notification_type, index) in $env.MODEL.user.notification_types" :key="index">
								<input type="checkbox" :id="`notification_types.${index}`" :value="index" v-model="form.notification_types" class="form-check-input">
								<label class="form-check-label ps-0 vstack" :for="`notification_types.${index}`">
									<b>{{ notification_type.title }}</b>
									<small>{{ notification_type.description }}</small>
								</label>
							</div>
						</div>
					</LayoutFormInputGeneral>
					<CommonFormInputImage v-model="form.avatar" :imageUrl="form?.avatar_url" title="Logo" :rounded="true" @remove="removeImage" />
					<div class="alert alert-success lh-xs vstack gap-2" role="alert">
						<div>
							<u class="fw-bold">Tu tienda:</u> para poder ofrecer tus productos, podés especificar un slug para que otras personas puedan explorar tus productos.
						</div>
						<router-link :to="{ name: 'account.setting.slug' }" class="alert-link">Configurar tienda</router-link>
					</div>
				</template>
			</CommonFormWrapper>
		</main>
	</LayoutMain>
</template>

<script>
	import { reactive, computed } from 'vue';
	import store from '@/store';
	import _ from 'lodash';
	import useVuelidate from '@vuelidate/core';
	import { helpers } from '@vuelidate/validators';
	import { required, minLength, maxLength } from '@/helpers/i18n/validators';
	import { isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js';
	import composableForm from '@/composables/form';
	import composableRedirect from '@/composables/redirect';
	import AuthRepository from '@/repositories/AuthRepository';

	export default {
		setup() {
			const { submitting, validationExternal, catchError } = composableForm();
			const { redirectBack } = composableRedirect();
			const user = computed(() => store.getters['auth/user']);

			const validatePhone = (value) => {
				if(!value) return true;
				else return isValidPhoneNumber(value);
			}

			const form = reactive({
				email: user.value?.email,
				name: user.value?.name,
				phone: user.value?.phone,
				avatar: null,
				avatar_url: user.value?.avatar_url,
				contact_types: user.value?.contact_types ?? [],
				notification_types: user.value?.notification_types ?? []
			});

			const validation = useVuelidate({
				name: {
					required,
					minLength: minLength(3),
					maxLength: maxLength(150)
				},
				phone: {
					validatePhone: helpers.withMessage('Debe incluir el código de país, incluyendo el signo +', validatePhone)
				}
			}, form);

			const submit = async () => {
				if(submitting.value) return;
				validationExternal.value = [];
				if(!await validation.value.$validate()) return;

				submitting.value = true;

				//Formatea el teléfono
				var sendForm = _.clone(form);
				if(sendForm.phone) sendForm.phone = parsePhoneNumber(sendForm.phone).number;

				await AuthRepository.update(sendForm).then((response) => {
					store.dispatch('auth/setUser', response.data);
					redirectBack();
				}).catch(catchError);

				submitting.value = false;
			}

			const removeImage = () => {
				form.avatar_url = null;
			}

			return { form, submit, removeImage, submitting, validation, validationExternal };
		}
	}
</script>