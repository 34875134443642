<template>
	<div>
		<div class="text-muted text-xs">Descripción del usuario</div>
		<div class="text-sm lh-sm text-break text-white-space">{{ description }}</div>
	</div>
</template>

<script>
	export default {
		props: {
			description: String
		}
	}
</script>