<template>
	<div class="card border-2 rounded-3" :class="`border-${info.style.color} text-${info.style.color}`">
		<div class="card-body p-3 hstack gap-2 align-items-baseline lh-xs">
			<i class="bi" :class="`bi-${info.style.icon}`" v-if="info.style.icon"></i>
			<div class="vstack">
				<div class="fw-bold">{{ info.title }}</div>
				{{ description ?? info.description }}
			</div>
		</div>
	</div>
</template>

<script>
	import { computed, inject } from 'vue';

	export default {
		props: {
			priority: Number,
			description: String
		},
		setup(props) {
			const $env = inject('$env');
			const info = computed(() => (props.priority in $env.MODEL.publication.priorities) ? $env.MODEL.publication.priorities[props.priority] : null);

			return { info };
		}
	}
</script>