<template>
	<LayoutTableCommonRow>
		<LayoutTableCommonPartText :text="search.title" :header="dateDiff" @select="select">
			<template #extras>
				<div class="text-sm" v-if="search.user">En <router-link :to="{ name: 'user.search.index', params: { slug: search.user.slug }}" class="link-dark fw-bold position-relative stretched-link">{{ search.user.name }}</router-link></div>
				<div class="text-sm" v-else-if="search.group">En <router-link :to="{ name: 'group.search.index', params: { id: search.group.id }}" class="link-dark fw-bold position-relative stretched-link">{{ search.group.name }}</router-link></div>
				<div class="text-sm" v-else>En todo el sitio</div>
			</template>
		</LayoutTableCommonPartText>
		<LayoutTableCommonPartRaw class="text-end">
			<div class="hstack gap-3 justify-content-end" v-if="search.users.length > 0">
				<div class="avatar-group">
					<UserAvatarRounded v-for="user in search.users" :key="user.id" :user="user" size="xs" v-tooltip:left="user.name" />
				</div>
			</div>
		</LayoutTableCommonPartRaw>
		<LayoutTableCommonPartMenu>
			<template #options>
				<router-link :to="{ name: 'search.result', params: { uuid: search.uuid }}" class="dropdown-item rounded-0 fw-bold">Continuar búsqueda</router-link>
				<router-link :to="{ name: 'account.search.filtered', params: { uuid: search.uuid }}" class="dropdown-item rounded-0 fw-bold">Ver resulatos</router-link>
				<div class="dropdown-divider"></div>
				<a href="javascript:void(0)" class="dropdown-item rounded-0" @click="remove">Eliminar</a>
			</template>
		</LayoutTableCommonPartMenu>
	</LayoutTableCommonRow>
</template>

<script>
	import { computed } from 'vue';
	import router from '@/router';
	import moment from 'moment';

	export default {
		emits: ['remove'],
		props: {
			search: Object
		},
		setup(props, { emit }) {
			const dateDiff = moment(props.search.created_at).fromNow();
			const select = () => router.push({ name: 'search.result', params: { uuid: props.search.uuid }});
			const remove = () => emit('remove', props.search);

			return { dateDiff, select, remove };
		}
	}
</script>