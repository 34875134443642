<template>
	<LayoutClean class="vstack mh-100 h-100">
		<LayoutHeaderMain :main="true" :fixed="true" shadow="ever" :style="$manifest?.microsite?.style?.header" />
		<div class="container-md mh-100 h-100 overflow-hidden">
			<UserIndexImagesGrid :images="user.images" />
			<UserIndexDescription :user="user" :style="$manifest?.microsite?.style?.description" />
		</div>
	</LayoutClean>
</template>

<script>
	import { onMounted, onUnmounted, inject } from 'vue';

	export default {
		name: 'ViewsUserHome',
		props: {
			user: Object
		},
		setup() {
			const $manifest = inject('$manifest');
			const themeColor = document.querySelector('meta[name="theme-color"]').content;

			onMounted(async () => {
				if($manifest?.microsite) {
					document.querySelector('meta[name="theme-color"]').content = $manifest.theme_color;
					document.body.style.backgroundColor = $manifest?.microsite?.style?.body?.style?.backgroundColor;
				}
			});

			onUnmounted(() => {
				if($manifest?.microsite) {
					document.querySelector('meta[name="theme-color"]').content = themeColor;
					document.body.style.backgroundColor = '';
				}
			});
		}
	}
</script>