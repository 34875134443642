<template>
	<button type="button"
		id="search-part-action-decision-true"
		class="btn btn-square btn-lg border-2 bg-white border-success text-success rounded-circle shadow p-3 pb-2 z-1"
		:class="{ 'isHeld': isHeld, 'is_super': is_super }"
		@mousedown.prevent="holdDetectionStart"
		@mouseup.prevent="holdDetectionEnd"
		@mouseleave.prevent="holdDetectionCancel"
		@touchstart.prevent="holdDetectionStart"
		@touchend.prevent="holdDetectionEnd"
		@touchcancel.prevent="holdDetectionCancel"
		:disabled="disabled">
		<i class="bi bi-heart-fill text-xl"></i>
	</button>
</template>

<script>
	import { ref } from 'vue';

	export default {
		emits: ['decision'],
		props: {
			disabled: Boolean
		},
		setup(props, { emit }) {
			const isHeld = ref(false);
			const is_super = ref(false);
			const timeoutHeld = ref(null);
			const timeoutSuper = ref(null);

			const holdDetectionStart = () => {
				timeoutHeld.value = setTimeout(() => isHeld.value = true, 500);
				timeoutSuper.value = setTimeout(() => is_super.value = true, 1500);
			}

			const holdDetectionEnd = () => {
				let triggerHold = is_super.value;
				holdDetectionCancel();

				if(triggerHold) emit('decision', true, true);
				else emit('decision', true, false);
			}

			const holdDetectionCancel = () => {
				clearTimeout(timeoutHeld.value);
				clearTimeout(timeoutSuper.value);
				isHeld.value = false;
				is_super.value = false;
			}

			return { isHeld, is_super, holdDetectionStart, holdDetectionEnd, holdDetectionCancel };
		}
	}
</script>

<style scoped>
	.isHeld {
		scale: 2;
		transition: scale .8s ease;
	}

	.is_super {
		color: white !important;
		background-color: rgba(var(--x-success-rgb), var(--x-border-opacity)) !important;
		transition: color .5s ease, background-color .5s ease;
		animation: shake 1s ease-in-out infinite;
	}

	@keyframes shake {
		0% { transform: translateX(0); }
		10%, 30%, 50%, 70%, 90% { transform: translateY(-1px); } /* Desplazamiento reducido */
		20%, 40%, 60%, 80% { transform: translateY(1px); } /* Desplazamiento reducido */
		100% { transform: translateY(0); }
	}
</style>