<template>
	<div class="text-sm lh-sm" :class="`text-${color}`"><i class="bi bi-info-circle me-1"></i><span v-html="info"></span></div>
</template>

<script>
	export default {
		props: {
			info: String,
			color: {
				type: String,
				default: 'warning'
			}
		}
	}
</script>