<template>
	<LayoutTableMain :results="results" :loading="loading" @goPage="goPage" :naming="{ singular: 'búsqueda publicada', plural: 'búsquedas publicadas' }">
		<template #filters>
			<div class="dropdown flex-shrink-0">
				<button type="button" class="btn btn-sm btn-neutral rounded-pill" data-bs-toggle="dropdown" aria-expanded="false">
					<template v-if="!filters.time">Cualquier momento</template>
					<template v-else-if="filters.time == 'day'">Último día</template>
					<template v-else-if="filters.time == 'week'">Última semana</template>
				</button>
				<div class="dropdown-menu px-0 mt-2">
					<a href="javascript:void(0);" class="dropdown-item rounded-0" @click="setTime(null);">Cualquier momento</a>
					<a href="javascript:void(0);" class="dropdown-item rounded-0 fw-bold" @click="setTime('day');">Último día</a>
					<a href="javascript:void(0);" class="dropdown-item rounded-0 fw-bold" @click="setTime('week');">Última semana</a>
				</div>
			</div>
			<div class="dropdown flex-shrink-0" v-if="Object.keys(categories).length > 1">
				<button type="button" class="btn btn-sm btn-neutral rounded-pill" data-bs-toggle="dropdown" aria-expanded="false">
					<template v-if="!filters.category">Todas las categorías</template>
					<template v-else>Sólo <b>{{ categories[filters.category] }}</b></template>
				</button>
				<div class="dropdown-menu px-0 mt-2">
					<a href="javascript:void(0);" class="dropdown-item rounded-0 lh-xs" @click="setCategory(null);">
						Todas las categorías
					</a>
					<a href="javascript:void(0);" class="dropdown-item rounded-0 lh-xs" v-for="(category, index) in categories" v-bind:key="index" @click="setCategory(index);">
						Sólo <b>{{ category }}</b>
					</a>
				</div>
			</div>
		</template>
		<template #body>
			<AccountGroupSearchTableRow v-for="data in results?.data" v-bind:key="data.id" :search="data" :user="user" @remove="remove" @reload="getResults" />
		</template>
	</LayoutTableMain>

	<CommonModalAction ref="modalRemove" title="Eliminar búsqueda" description="¿Desea eliminar esta búsqueda? Al eliminarla, ya no podrás acceder nuevamente" submitText="Eliminar" submitClass="btn-danger" :target="target" @success="destroy" />
</template>

<script>
	import { ref, reactive, computed, watch, onMounted } from 'vue';
	import store from '@/store';
	import router from '@/router';
	import composableForm from '@/composables/form';
	import AccountGroupSearchRepository from '@/repositories/Account/GroupSearchRepository';
	import AccountSearchRepository from '@/repositories/Account/SearchRepository';

	export default {
		props: {
			group: Object
		},
		setup(props) {
			const { loading, catchError } = composableForm();
			const user = computed(() => store.getters['auth/user']);
			const results = ref({});
			const categories = ref([]);
			const filters = reactive({
				search: null,
				time: null,
				category: null,
				orderBy: 'published_at',
				orderMethod: 'desc',
				take: 20,
				page: 1
			});

			const getResults = async () => {
				loading.value = true;

				await AccountGroupSearchRepository.index(props.group.id, filters).then((response) => {
					results.value = response.data;
				}).catch((e) => {
					catchError(e, true);
					router.push({ name: 'home' });
				});

				loading.value = false;
			}

			const getCategories = async () => {
				await AccountGroupSearchRepository.categories(props.group.id).then((response) => {
					categories.value = response.data;
				});
			}

			const setTime = (time) => {
				if(filters.time == time) return;
				filters.time = time;
				refresh();
			}

			const setCategory = (category) => {
				if(filters.category == category) return;
				filters.category = category;
				refresh();
			}

			const refresh = () => {
				results.value = {};
				goPage(1);
			}

			const goPage = (page) => {
				filters.page = page;
				getResults();
			}

			const target = ref(null);
			const modalRemove = ref(null);
			const remove = (search) => {
				target.value = search;
				modalRemove.value.show();
			}
			const destroy = (search) => {
				AccountSearchRepository.destroy(search.id).then(() =>{
					store.dispatch('auth/removeSearch', search);
					getResults();
				}).catch((e) => catchError(e, true));
			}

			const timeout = ref(null);
			watch(() => filters.search, () => {
				if(timeout.value) clearTimeout(timeout.value);
				timeout.value = setTimeout(() => goPage(1), 1000);
			});

			onMounted(() => {
				getResults();
				getCategories();
			});

			return { user, loading, filters, categories, results, getResults, setTime, setCategory, goPage, target, modalRemove, remove, destroy };
		}
	}
</script>