<template>
	<div class="rounded-pill px-3 ps-2 py-1 lh-1 hstack gap-1 border border-2 shadow fw-bold bg-white border-success text-success" v-if="public">
		<i class="bi bi-unlock me-1"></i>Pública
	</div>
	<div class="rounded-pill px-3 ps-2 py-1 lh-1 hstack gap-1 border border-2 shadow fw-bold bg-white border-warning text-warning" v-else>
		<i class="bi bi-lock me-1"></i>Privada
	</div>
</template>

<script>
	export default {
		props: {
			public: Boolean
		}
	}
</script>