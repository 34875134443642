<template>
	<div class="hstack gap-3 align-items-center">
		<GroupCommonImage :src="group?.logo_url" size="md" rounded="3" :border="true" class="mx-auto" />
		<div class="flex-fill lh-sm text-limit">
			<div class="text-md text-truncate fw-bold">{{ group?.name }}</div>
			<div class="text-sm text-truncate text-muted hstack gap-1" v-if="group?.pivot || subtitle">
				<div class="fw-bold" v-if="group?.pivot">{{ (group?.pivot.admin) ? 'Administrador' : 'Miembro' }}</div>
				<div v-if="group?.pivot && subtitle">·</div>
				<div v-if="subtitle">{{ subtitle }}</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			group: Object,
			subtitle: {
				type: String,
				default: null
			}
		}
	}
</script>