<template>
	<LayoutTableMain :results="results" :loading="loading" @goPage="goPage" :naming="{ singular: 'miembro', plural: 'miembros' }">
		<template #filters>
			<div class="input-group input-group-sm rounded-pill">
				<span class="input-group-text bg-white rounded-start-pill pe-0" id="basic-addon1"><i class="bi bi-search"></i></span>
				<input type="text" v-model="filters.search" class="form-control border-start-0 rounded-end-pill" placeholder="Buscar..." />
			</div>
			<a href="javascript:void(0);" class="btn btn-sm btn-dark rounded-pill flex-shrink-0" @click="invite" v-if="group.pivot.admin">Invitar</a>
		</template>
		<template #body>
			<AccountGroupUserTableRow v-for="data in results?.data" v-bind:key="data.id" :user="data" :edit="(data.id != user.id && group.pivot.admin)" @options="options" />
		</template>
	</LayoutTableMain>

	<AccountGroupUserModalOption ref="modalOption" :group="group" :user="target" @reload="getResults" />
	<AccountGroupUserInvitation ref="modalInvitation" :group="group" />
</template>

<script>
	import { ref, reactive, computed, watch, onMounted } from 'vue';
	import store from '@/store';
	import router from '@/router';
	import composableForm from '@/composables/form';
	import AccountGroupUserRepository from '@/repositories/Account/GroupUserRepository';

	export default {
		props: {
			group: Object
		},
		setup(props) {
			const { loading, catchError } = composableForm();
			const user = computed(() => store.getters['auth/user']);
			const results = ref({});
			const filters = reactive({
				search: null,
				orderBy: 'created_at',
				orderMethod: 'desc',
				take: 20,
				page: 1
			});

			const target = ref(null);
			const modalOption = ref(null);
			const options = (user) => {
				target.value = user;
				modalOption.value.show();
			}

			const modalInvitation = ref(null);
			const invite = () => modalInvitation.value.show();

			const getResults = async () => {
				loading.value = true;

				await AccountGroupUserRepository.index(props.group.id, filters).then((response) => {
					results.value = response.data;
				}).catch((e) => {
					catchError(e, true);
					router.push({ name: 'home' });
				});

				loading.value = false;
			}

			const goPage = (page) => {
				filters.page = page;
				getResults();
			}

			const timeout = ref(null);
			watch(() => filters.search, () => {
				if(timeout.value) clearTimeout(timeout.value);
				timeout.value = setTimeout(() => goPage(1), 1000);
			});

			onMounted(getResults);

			return { user, loading, filters, results, getResults, goPage, target, modalOption, options, modalInvitation, invite };
		}
	}
</script>