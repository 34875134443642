<template>
	<LayoutOffcanvasStandard ref="offcanvas" title="Recibí notificaciones" id="app-permission-notification">
		<template #body>
			<div class="vstack">
				<h3 class="fw-bolder text-dark">¿Querés recibir notificaciones?</h3>
				<p class="text-md text-muted lh-sm">De esta manera podrás recibir notificaciones importantes de <b>{{ viteAppName }}</b>.</p>
			</div>
		</template>
		<template #footer>
			<button type="button" class="btn btn-neutral rounded-pill" @click="dismiss">Ahora no</button>
			<button type="button" class="btn btn-primary rounded-pill" @click="accept">Aceptar</button>
		</template>
	</LayoutOffcanvasStandard>
</template>

<script>
	import { ref, computed, watch, onMounted, onBeforeUnmount, inject } from 'vue';
	import store from '@/store';
	import router from '@/router';
	import { useToast } from 'vue-toastification';
	import { VueCookieNext } from 'vue-cookie-next';
	import { initializeApp } from 'firebase/app';
	import { getMessaging, getToken } from 'firebase/messaging';
	import composableForm from '@/composables/form';
	import AuthRepository from '@/repositories/AuthRepository';

	export default {
		setup() {
			const $env = inject('$env');
			const $manifest = inject('$manifest');
			const viteAppName = import.meta.env.VITE_APP_NAME;
			const { catchError } = composableForm();
			const offcanvas = ref(null);
			const toast = useToast();
			const timeout = ref(null);
			const hasUser = computed(() => store.getters['auth/hasUser']);
			const user = computed(() => store.getters['auth/user']);
			const firebaseApp = ref(null);
			const messaging = ref(null);

			const permissionNotificationsShow = () => offcanvas.value.offcanvas.show();
			const permissionNotificationsHide = () => offcanvas.value.offcanvas.hide();

			const firebaseConfig = {
				apiKey: $env.FIREBASE.apiKey,
				authDomain: $env.FIREBASE.authDomain,
				projectId: $env.FIREBASE.projectId,
				storageBucket: $env.FIREBASE.storageBucket,
				messagingSenderId: $env.FIREBASE.messagingSenderId,
				appId: $env.FIREBASE.appId
			};

			const dismiss = () => {
				permissionNotificationsHide();
				VueCookieNext.setCookie('permission-notification', null, { expire: '10d' });
			}

			const accept = async () => {
				permissionNotificationsHide();
				await Notification.requestPermission().then(async (permission) => {
					if(permission === 'granted') await setToken();
					else toast.error(`Permiso de notificación denegado.`);
				});
			}

			const setToken = async () => {
				if(hasUser.value) await getToken(messaging.value, { vapidKey: $env.FIREBASE.vapidKey }).then(async (token) => {
					if(token) {
						let site_id = `site-${($manifest?.microsite?.id) ? $manifest.microsite.id : 0}`,
							notification_push_identifiers = user.value?.notification_push_identifiers ?? {};

						if(!notification_push_identifiers.hasOwnProperty(site_id) || notification_push_identifiers[site_id] != token) {
							await AuthRepository.pushSubscribe({ token: token }).then((response) => {
								store.dispatch('auth/setUser', response.data);
							}).catch((error) => toast.error(`Error al suscribir: ${error.message || 'unknown'}`));
						}
					}else toast.error('No se pudo generar el token de notificaciones.');
				}).catch((error) => {
					toast.error(`Error al obtener el token: ${error.message || 'unknown'}`);
				});
			}

			const previousShow = () => {
				if(VueCookieNext.isCookieAvailable('permission-notification') || !hasUser.value) return false;
				else if(document.querySelector('.modal.show') || document.querySelector('.offcanvas.show')) timeout.value = setTimeout(previousShow, 60000);
				else permissionNotificationsShow();
			}

			const clientCheck = () => {
				switch(Notification.permission) {
					case 'default':
						timeout.value = setTimeout(previousShow, 5000);
						break;
					case 'granted':
						setToken();
						break;
				}
			}

			watch(hasUser, () => {
				clientCheck();
			});

			onMounted(() => {
				if('serviceWorker' in navigator && 'Notification' in window) {
					navigator.serviceWorker.register('/firebase-messaging-sw.js', { scope: '/firebase-cloud-messaging-push-scope' }).then(() => {
						firebaseApp.value = initializeApp(firebaseConfig);
						messaging.value = getMessaging(firebaseApp.value);
						clientCheck();
					});

					navigator.serviceWorker.addEventListener('message', (event) => {
						if(event.data && event.data.type === 'navigate' && event.data?.url) {
							const relativeUrl = new URL(event.data.url).pathname;
							if(relativeUrl) router.push(relativeUrl);
						}
					});
				}
			});

			onBeforeUnmount(() => {
				if(timeout.value) clearTimeout(timeout.value);
			});

			return { viteAppName, offcanvas, dismiss, accept };
		}
	}
</script>