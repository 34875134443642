<template>
	<LayoutOffcanvasStandard ref="offcanvas" title="Historial de búsqueda" id="search-modal-list" footerClass="container-md hstack gap-3">
		<template #body>
			<div class="list-group list-group-flush gap-5">
				<div v-for="search in searches" v-bind:key="search.id" class="list-group-item hstack gap-5 border-0 p-0">
					<div>
						<a href="javascript:void(0)" @click="routerPush({ name: 'search.result', params: { uuid: search.uuid }})" class="d-block h5 font-semibold stretched-link">{{ search.title }}</a>
						<div class="text-sm lh-sm">Creada {{ dateDiff(search.created_at) }}</div>
					</div>
					<div class="ms-auto text-end align-self-center">
						<i class="bi bi-chevron-right"></i>
					</div>
				</div>
			</div>
		</template>
		<template #footer>
			<div class="hstack gap-4 align-items-center">
				<a href="javascript:void(0)" @click="routerPush({ name: 'account.search.index' })" class="link-primary">Ver listado completo</a>
			</div>
		</template>
	</LayoutOffcanvasStandard>
</template>

<script>
	import { ref } from 'vue';
	import router from '@/router';
	import moment from 'moment';

	export default {
		props: {
			searches: Array
		},
		setup(props) {
			const offcanvas = ref(null);
			const show = () => offcanvas.value.offcanvas.show();
			const dateDiff = (date) => moment(date).fromNow();

			const routerPush = (options) => {
				document.getElementById('search-modal-list').addEventListener('hidden.bs.offcanvas', () => router.push(options));
				offcanvas.value.offcanvas.hide();
			}

			return { offcanvas, show, dateDiff, routerPush };
		}
	}
</script>