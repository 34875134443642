<template>
	<div class="dropdown">
		<button class="btn btn-square rounded-circle" :class="btnClass" type="button" data-bs-toggle="dropdown" aria-expanded="false"><i class="bi bi-three-dots-vertical"></i></button>
		<div class="dropdown-menu px-0 mt-2">
			<router-link :to="{ name: 'account.group.show', params: { id: group?.id }}" class="dropdown-item rounded-0 fw-bold" v-if="route.name != 'account.group.show'">Detalle del grupo</router-link>
			<router-link :to="{ name: 'account.group.search', params: { id: group?.id }}" class="dropdown-item rounded-0 fw-bold" v-if="route.name != 'account.group.search'">Búsquedas activas</router-link>
			<router-link :to="{ name: 'account.group.publication', params: { id: group?.id }}" class="dropdown-item rounded-0 fw-bold" v-if="route.name != 'account.group.publication'">Cartera Interna</router-link>
			<router-link :to="accountPublicationCreateRoute" class="dropdown-item rounded-0 fw-bold">Nueva publicación</router-link>
		</div>
	</div>
</template>

<script>
	import { computed } from 'vue';
	import { useRoute } from 'vue-router';

	export default {
		props: {
			group: Object,
			btnClass: {
				type: String,
				default: 'btn-neutral btn-sm text-muted'
			}
		},
		setup(props) {
			const route = useRoute();

			const accountPublicationCreateRoute = computed(() => {
				let route = { name: 'account.publication.create' };
				if(props.group?.public === false) route.query = { public: props.group.public };

				return route;
			});

			return { route, accountPublicationCreateRoute };
		}
	}
</script>