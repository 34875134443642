<template>
	<div class="vstack gap-3">
		<div class="hstack gap-5 justify-content-between align-items-center position-relative">
			<div class="hstack gap-2 text-limit">
				<div class="avatar-group flex-shrink-0">
					<UserAvatarRounded v-for="user in publication?.users" :key="user.id" :user="user" size="xs" />
				</div>
				<a href="javascript:void(0);" class="link-dark text-sm fw-bold text-truncate stretched-link" @click="select">{{ userNames }}</a>
			</div>
			<div class="text-sm text-muted flex-shrink-0">{{ dateDiff }}</div>
		</div>
		<div v-if="medias.length > 0">
			<div class="overflow-hidden position-relative shadow w-100 h-100 object-cover rounded-4">
				<Carousel :items-to-show="1" :wrap-around="true">
					<Slide v-for="media in medias" :key="media.id">
						<div class="carousel__item">
							<img :src="media.file_url" v-placeholder-image class="object-cover position-absolute top-50 start-50 translate-middle" />
						</div>
					</Slide>
					<template #addons v-if="medias.length > 1">
						<Pagination />
					</template>
				</Carousel>
			</div>
		</div>
		<div class="vstack gap-1 position-relative">
			<div class="text-sm fw-bold lh-1" v-if="publication?.like_count !== undefined"><i class="bi bi-heart-fill text-xs me-2"></i>{{ publication.like_count }} Me gusta</div>
			<a href="javascript:void(0);" class="link-dark text-md lh-xs stretched-link" @click="select">{{ publication.title }}</a>
			<PublicationPartBadge :publication="publication" :scrollable="false" />
		</div>
	</div>
</template>

<script>
	import { computed } from 'vue';
	import _ from 'lodash';
	import moment from 'moment';
	import { Carousel, Slide, Pagination } from 'vue3-carousel';

	export default {
		components: { Carousel, Slide, Pagination },
		emits: ['select'],
		props: {
			group: Object,
			publication: Object
		},
		setup(props, { emit }) {
			const medias = computed(() => {
				if((props.publication?.medias ?? []).length > 0) return _.take(props.publication.medias, 5);
				else return [];
			});

			const dateDiff = moment(props.publication.listed_at).fromNow();
			const names = props.publication.users.map(user => user.name);
			const userNames = names.length > 1 ? _.initial(names).join(', ') + ' y ' + _.last(names) : names[0];
			const select = () => emit('select', props.publication);

			return { medias, dateDiff, userNames, select };
		}
	}
</script>