<template>
	<nav class="navbar navbar-expand-lg navbar-light px-0 z-3" :class="{ 'fixed-top': fixed, 'header-shadow': (shadow == 'ever') }" :style="style?.style">
		<div class="container-md hstack gap-2 align-items-center justify-content-between flex-nowrap">
			<router-link :to="{ name: 'home' }">
				<img :src="logo" class="img-fluid">
			</router-link>
			<div class="flex-shrink-0 hstack gap-2 align-items-center">
				<a href="javascript:void(0);" class="btn btn-sm py-2 ps-2 pe-3 border shadow-2-hover bg-white rounded-pill" :style="style?.button" @click="redirectBack" v-if="displayRedirectBack">
					<i class="bi bi-chevron-left pe-1"></i>Volver
				</a>
				<div class="hstack gap-3">
					<UserCardMenu :user="user" :style="style?.button" v-if="hasUser" />
					<UserCardMenuEmpty :style="style?.button" v-else />
				</div>
			</div>
		</div>
	</nav>
</template>

<script>
	import { computed, inject } from 'vue';
	import store from '@/store';
	import { useRoute } from 'vue-router';
	import composableRedirect from '@/composables/redirect';
	import assetsImgLogoComplete from '@/assets/img/logo/complete.png';

	export default {
		props: {
			main: {
				type: Boolean,
				default: false
			},
			fixed: {
				type: Boolean,
				default: false
			},
			shadow: {
				type: String,
				default: 'none'
			},
			style: Object
		},
		setup(props) {
			const { hasRedirectBack, redirectBack } = composableRedirect();
			const $manifest = inject('$manifest');
			const route = useRoute();
			const hasUser = computed(() => store.getters['auth/hasUser']);
			const user = computed(() => store.getters['auth/user']);
			const logo = computed(() => {
				if(props.main && $manifest?.microsite?.logo?.main) return $manifest.microsite.logo.main;
				else if(!props.main && $manifest?.microsite?.logo?.secondary) return $manifest.microsite.logo.secondary;
				else return assetsImgLogoComplete;
			});

			const displayRedirectBack = computed(() => {
				return (hasRedirectBack.value && !($manifest?.microsite && route.name == 'user.home'));
			});

			return { redirectBack, route, hasUser, user, logo, displayRedirectBack };
		}
	}
</script>

<style scoped>
	.navbar-nav > .nav-item > .nav-link, .navbar-nav > .nav-link {
		background-color: inherit;
	}

	.navbar-nav > .nav-item > .nav-link:hover, .navbar-nav > .nav-item > .nav-link:focus, .navbar-nav > .nav-link:hover, .navbar-nav > .nav-link:focus {
		background-color: inherit;
	}

	.item-search {
		min-width: 240px;
	}

	.header-shadow {
		box-shadow: 0 2px 4px 0 rgba(32, 33, 36, .3);
	}

	.fixed-top {
		background-color: rgb(255 255 255 / 90%);
		backdrop-filter: blur(3px);
	}

	.img-fluid {
		max-height: 2rem !important;
	}
</style>
