<template>
	<a href="javascript:void(0);" class="text-dark border border-2 border-dark hstack gap-2 p-2 pe-5 rounded-pill position-relative" :style="style" @click="select">
		<GroupCommonImage :src="group?.logo_url" size="sm" rounded="pill" :border="true" class="mx-auto" />
		<div class="flex-fill lh-xs text-limit">
			<div class="text-md text-truncate text-reset fw-bold">{{ group?.name }}</div>
			<div class="text-sm text-truncate text-reset hstack gap-1" v-if="group?.pivot || subtitle">
				<div class="fw-bold" v-if="group?.pivot">{{ (group?.pivot.admin) ? 'Administrador' : 'Miembro' }}</div>
				<div v-if="group?.pivot && subtitle">·</div>
				<div v-if="subtitle">{{ subtitle }}</div>
			</div>
		</div>
	</a>
</template>

<script>
	export default {
		emits: ['select'],
		props: {
			group: Object,
			style: Object,
			subtitle: String
		},
		setup(props, { emit }) {
			const select = () => emit('select');

			return { select };
		}
	}
</script>