<template>
	<div class="text-lg lh-sm" :class="{'hstack gap-2 align-items-start scrollable-x': scrollable}" v-if="list.length > 0">
		<span class="badge rounded-pill text-sm" v-for="(description, index) in list" :key="index" :class="{'me-2': !scrollable, 'bg-light text-muted': (color == 'white'), 'bg-dark text-white': (color == 'dark')}">{{ description }}</span>
	</div>
</template>

<script>
	import { computed } from 'vue';

	export default {
		props: {
			publication: Object,
			hidden: {
				type: Array,
				default: []
			},
			color: {
				type: String,
				default: 'white'
			},
			scrollable: {
				type: Boolean,
				default: true
			}
		},
		setup(props) {
			const list = computed(() => {
				let numberFormat = new Intl.NumberFormat('es-ES'),
					array = [];

				if(!props.hidden.includes('amount') && props.publication.currency && (props.publication.amount || props.publication.amount_sale)) array.push(`${numberFormat.format(props.publication.amount_sale ?? props.publication.amount)} ${props.publication.currency}`);
				if(props.publication.extras?.credit) array.push(`Apto crédito`);
				if(props.publication.extras?.reserved) array.push(`Reservada`);
				if(props.publication.extras?.areas?.total) array.push(`${numberFormat.format(props.publication.extras.areas.total)} m2 tot.`);
				if(props.publication.extras?.areas?.covered) array.push(`${numberFormat.format(props.publication.extras.areas.covered)} m2 cub.`);
				if(props.publication.extras?.rooms?.total) array.push(`${numberFormat.format(props.publication.extras.rooms.total)} amb.`);
				if(props.publication.extras?.parkings && props.publication.extras?.parkings > 0) array.push(`${numberFormat.format(props.publication.extras.parkings)} coch.`);
				if(props.publication.extras?.brand) array.push(props.publication.extras.brand);
				if(props.publication.extras?.last_season) array.push(`Última temporada`);
				if((props.publication.extras?.tags ?? []).length > 0) array.push(props.publication.extras.tags.join(', '));
				if(props.publication.extras?.stock?.size) array.push(`Talle ${props.publication.extras.stock.size}`);

				return array;
			});

			return { list };
		}
	}
</script>
