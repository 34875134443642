<template>
	<GoogleMap
		ref="mapRef"
		:api-key="$env.GOOGLE_API_KEY"
		:zoom="16"
		:center="center"
		:disableDefaultUi="true"
		:styles="[{ featureType: 'poi', stylers: [{ visibility: 'off' }]}, { featureType: 'transit', stylers: [{ visibility: 'off' }]}, { featureType: 'administrative', stylers: [{ visibility: 'off' }]}]"
		:style="mapStyle"
		:class="mapClass"
		mapTypeId="roadmap">
		<slot />
	</GoogleMap>
</template>

<script>
	import { ref } from 'vue';
	import { GoogleMap } from 'vue3-google-map';

	export default {
		components: { GoogleMap },
		props: {
			center: Object,
			mapStyle: {
				type: String,
				default: 'height: 250px; width: 100%;'
			},
			mapClass: {
				type: String,
				default: 'border border-2 border-dark rounded-4 overflow-hidden mt-2'
			}
		},
		setup(props) {
			const mapRef = ref(null);

			const reset = () => {
				mapRef.value.map.setZoom(16);
				mapRef.value.map.setCenter(props.center);
			}

			return { mapRef, reset };
		}
	}
</script>