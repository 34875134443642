<template>
	<div class="avatar media-wrapper rounded-circle square bg-light" :class="`avatar-${size}`">
		<img :src="assetsImgIconsAvatar" v-placeholder-image class="object-cover" alt="Avatar">
	</div>
</template>

<script>
	import assetsImgIconsAvatar from '@/assets/img/icons/avatar.jpg';

	export default {
		props: {
			size: {
				type: String,
				default: 'md'
			}
		},
		setup() {
			return { assetsImgIconsAvatar };
		}
	}
</script>