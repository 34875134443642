<template>
	<div class="avatar media-wrapper rounded-circle square bg-light" :class="avatarClass">
		<img :src="avatarUrl" v-placeholder-image class="object-cover" alt="Avatar">
	</div>
</template>

<script>
	import { computed } from 'vue';
	import assetsImgIconsAvatar from '@/assets/img/icons/avatar.jpg';

	export default {
		props: {
			user: Object,
			border: String,
			size: {
				type: String,
				default: 'md'
			}
		},
		setup(props) {
			const avatarUrl = computed(() => {
				if(props.user?.avatar_url) return props.user.avatar_url;
				else return assetsImgIconsAvatar;
			});

			const avatarClass = computed(() => {
				let list = [ `avatar-${props.size}` ];
				if(props.border) list.push(`border border-2 border-${props.border}`);

				return list;
			});

			return { avatarUrl, avatarClass };
		}
	}
</script>