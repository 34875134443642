<template>
	<div class="image w-100 rounded-3 overflow-hidden border border-2 position-relative">
		<div class="position-absolute m-1 lh-1 hstack gap-1">
			<a href="javascript:void(0);" class="btn btn-light btn-square btn-xs text-xs" @click="remove"><i class="bi bi-trash-fill"></i></a>
		</div>
		<img :src="fileUrl" class="object-cover">
	</div>
</template>

<script>
	import { computed } from 'vue';

	export default {
		emits: ['remove'],
		props: {
			media: Object,
			index: Number
		},
		setup(props, { emit }) {
			const fileUrl = computed(() => {
				if(props.media?.file_url) return props.media.file_url;
				else return URL.createObjectURL(props.media.file);
			});

			const remove = () => {
				emit('remove', props.index);
			}

			return { fileUrl, remove };
		}
	}
</script>

<style scoped>
	.image {
		aspect-ratio: 1 / 1.4;
	}
</style>