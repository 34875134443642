<template>
	<LayoutOffcanvasStandard ref="offcanvas" title="Mis grupos" id="user-modal-group" bodyClass="container-md vstack gap-5 pt-2">
		<template #body>
			<div class="vstack gap-5">
				<div class="list-group list-group-flush" v-if="user.groups.length > 0">
					<GroupCardOption v-for="group in user.groups" :key="group.id" :group="group" />
				</div>
			</div>
		</template>
	</LayoutOffcanvasStandard>
</template>

<script>
	import { ref, computed } from 'vue';
	import store from '@/store';

	export default {
		setup() {
			const offcanvas = ref(null);
			const user = computed(() => store.getters['auth/user']);
			const show = () => offcanvas.value.offcanvas.show();

			return { offcanvas, user, show };
		}
	}
</script>