<template>
	<CommonFormInputSelect v-model="config.service" title="Servicio" placeholder="Seleccionar el tipo de servicio" :options="services" />
	<template v-if="config?.service == 'octoparse'">
		<CommonFormInputText v-model="config.username" title="Usuario" placeholder="Ingrese su nombre de usuario de Octoparse" />
		<CommonFormInputPassword v-model="config.password" title="Contraseña" placeholder="Ingrese su contraseña de Octoparse" />
		<CommonFormInputText v-model="config.taskId" title="Task ID" placeholder="Ingrese el Task ID" />
	</template>
</template>

<script>
	import { ref, watch } from 'vue';

	export default {
		emits: ['update:modelValue'],
		props: {
			modelValue: Object
		},
		setup(props, { emit }) {
			const config = ref(props.modelValue);
			const services = {
				'octoparse': 'Octoparse'
			};

			watch(config.value, (newValue) => {
				emit('update:modelValue', newValue);
			});

			return { config, services };
		}
	}
</script>