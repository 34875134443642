<template>
	<div class="vstack">
		<div class="hstack gap-14 justify-content-between mb-4" v-if="title || $slots.filters">
			<h5 class="py-2" v-if="title">{{ title }}</h5>
			<div class="hstack gap-2 flex-fill flex-sm-grow-0" v-if="$slots.filters">
				<slot name="filters" />
			</div>
		</div>
		<div :class="{ 'table-responsive': responsive }">
			<table class="table" :class="tableClasses">
				<thead class="table-light" v-if="$slots.head">
					<slot name="thead" />
				</thead>
				<tbody v-if="$slots.body">
					<tr v-if="loading && (results?.data ?? []).length == 0">
						<td :colspan="columns" class="placeholder-glow">
							<span class="col-3 placeholder rounded-pill"></span>
						</td>
					</tr>
					<slot name="body" />
				</tbody>
			</table>
		</div>
		<div class="pt-4 hstack gap-4">
			<div class="btn-group btn-group-sm" role="group" v-if="hasPages">
				<button type="button" class="btn btn-neutral btn-square rounded-start-pill" @click="goBack" :disabled="loading || !results.prev_page_url"><i class="bi bi-chevron-left"></i></button>
				<button type="button" class="btn btn-neutral btn-square rounded-end-pill" @click="goNext" :disabled="loading || !results.next_page_url"><i class="bi bi-chevron-right"></i></button>
			</div>
			<span class="hstack gap-2 align-items-center text-muted text-sm lh-sm" v-if="loading">
				<div class="spinner-border spinner-border-sm" role="status">
					<span class="visually-hidden">Loading...</span>
				</div>
				<span>Cargando resultados</span>
			</span>
			<span class="text-muted text-sm lh-sm" v-else-if="results.total == 0">No hay resultados</span>
			<span class="text-muted text-sm lh-sm" v-else>
				<span v-if="hasPages">Página <b>{{ results.current_page }}</b> de <b>{{ results.last_page }}</b>, con </span>
				<b>{{ results.total }}</b> {{ (results.total == 1) ? (naming?.singular ?? 'resultado') : (naming?.plural ?? 'resultados') }}
			</span>
		</div>
	</div>
</template>

<script>
	import { computed } from 'vue';

	export default {
		emits: ['goPage'],
		props: {
			title: String,
			nowrap: Boolean,
			results: {
				type: Object,
				default: []
			},
			loading: Boolean,
			columns: Number,
			responsive: {
				type: Boolean,
				default: false
			},
			tableClass: {
				type: String,
				default: 'table-compact'
			},
			naming: Object
		},
		setup(props, { emit }) {
			const tableClasses = [
				props.tableClass,
				(props.nowrap) ? 'table-nowrap' : null
			];

			const hasPages = computed(() => {
				return (props.results.prev_page_url || props.results.next_page_url);
			});

			const goBack = () => {
				emit('goPage', (props.results.current_page - 1));
			}

			const goNext = () => {
				emit('goPage', (props.results.current_page + 1));
			}

			return { tableClasses, hasPages, goBack, goNext };
		}
	}
</script>