<template>
	<div class="hstack align-items-start">
		<button class="btn btn-xs btn-link text-muted mt-n2 px-1" :disabled="submitting" @click="remove" v-if="canRemove"><i class="bi bi-trash"></i></button>
		<button class="btn btn-xs btn-link text-primary mt-n2 px-1 me-n1" :disabled="submitting" @click="unpin" v-if="search.pinneds_count > 0"><i class="bi bi-pin-fill"></i></button>
		<button class="btn btn-xs btn-link text-muted mt-n2 px-1 me-n1" :disabled="submitting" @click="pin" v-else><i class="bi bi-pin-angle"></i></button>
	</div>
</template>

<script>
	import _ from 'lodash';
	import composableForm from '@/composables/form';
	import AccountGroupSearchRepository from '@/repositories/Account/GroupSearchRepository';

	export default {
		emits: ['remove', 'reload'],
		props: {
			search: Object,
			user: Object
		},
		setup(props, { emit }) {
			const { submitting, catchError } = composableForm();
			const canRemove = _.some(props.search.users, { id: props.user.id, pivot: { owner: true }});
			const remove = () => emit('remove');

			const pin = async () => {
				submitting.value = true;

				await AccountGroupSearchRepository.pin(props.search.group_id, props.search.id).then(() => {
					emit('reload');
				}).catch((e) => {
					catchError(e, true);
				});

				submitting.value = false;
			}

			const unpin = async () => {
				submitting.value = true;

				await AccountGroupSearchRepository.unpin(props.search.group_id, props.search.id).then(() => {
					emit('reload');
				}).catch((e) => {
					catchError(e, true);
				});

				submitting.value = false;
			}

			return { submitting, canRemove, remove, pin, unpin };
		}
	}
</script>