<template>
	<LayoutTableCommonRow>
		<LayoutTableCommonPartImage :src="image.file_url" size="lg" @select="select" />
		<LayoutTableCommonPartText :text="publication.title ?? 'Sin título'" @select="select">
			<template #extras>
				<PublicationPartBadge :publication="publication" :scrollable="false" />
				<div class="lh-sm hstack gap-2 align-items-start scrollable-x" v-if="!publication.public || publication.priority">
					<PublicationPartPublic :public="publication.public" v-if="!publication.public" />
					<PublicationPartPriority :priority="publication.priority" />
				</div>
			</template>
		</LayoutTableCommonPartText>
		<LayoutTableCommonPartMenu>
			<template #options>
				<router-link :to="{ name: 'account.publication.edit', params: { id: publication.id }}" class="dropdown-item rounded-0 fw-bold" v-if="publication.pivot.creator && publication.created_by == 'user'">Editar</router-link>
				<a href="javascript:void(0);" class="dropdown-item rounded-0 fw-bold" @click="select">Definir prioridad</a>
				<router-link :to="{ name: 'stat.publication.index', params: { id: publication.id }}" class="dropdown-item rounded-0 fw-bold">Superlikes</router-link>
				<template v-if="publication.created_by == 'user'">
					<div class="dropdown-divider"></div>
					<a href="javascript:void(0)" class="dropdown-item rounded-0" @click="remove">Eliminar</a>
				</template>
				<template v-if="publication.created_by == 'synchronizer' || publication.pivot.creator">
					<div class="dropdown-divider"></div>
					<div class="dropdown-item-text py-1 rounded-0 lh-xs text-muted" v-if="publication.created_by == 'synchronizer'">Sincronizada automáticamente</div>
					<div class="dropdown-item-text py-1 rounded-0 lh-xs text-muted" v-else-if="publication.pivot.creator">Eres el creador de la publicación</div>
				</template>
			</template>
		</LayoutTableCommonPartMenu>
	</LayoutTableCommonRow>
</template>

<script>
	import _ from 'lodash';

	export default {
		emits: ['select', 'remove'],
		props: {
			publication: Object
		},
		setup(props, { emit }) {
			const image = _.head(_.filter(props.publication.medias, { type: 'image' }));

			const select = () => emit('select', props.publication);
			const remove = () => emit('remove', props.publication);

			return { image, select, remove };
		}
	}
</script>

<style scoped>
	.dropdown-menu {
		width: 180px;
	}
</style>