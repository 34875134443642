<template>
	<div class="card rounded-4">
		<div class="card-body p-4">
			<UserCardMedium :user="user" :subtitle="subtitle" />
			<div class="hstack gap-2 justify-content-between mt-2">
				<router-link :to="{ name: 'user.home', params: { slug: user.slug }}" class="text-muted text-sm">Ingresar a la portada</router-link>
				<router-link :to="{ name: 'user.search.index', params: { slug: user.slug }}" class="btn btn-xs btn-neutral rounded-pill">Buscar</router-link>
			</div>
		</div>
	</div>
</template>

<script>
	import { computed } from 'vue';
	import moment from 'moment';

	export default {
		props: {
			user: Object
		},
		setup(props, { emit }) {
			const subtitle = computed(() => {
				return `Desde el ${moment(props.user?.created_at).format('DD/MM/YYYY')}`;
			});

			return { subtitle };
		}
	}
</script>