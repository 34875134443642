<template>
	<LayoutMain>
		<main class="container-md py-8 vstack gap-7" v-if="!loading">
			<div>
				<div class="vstack gap-4 text-center">
					<AccountGroupCardHeader :group="group" />
					<div class="hstack gap-2 justify-content-center">
						<router-link :to="{ name: 'group.search.index', params: { id: group.id }}" class="btn btn-outline-dark border-2 fw-bold px-10 text-uppercase rounded-pill">Buscar en cartera</router-link>
						<AccountGroupCommonMenu :group="group" btnClass="btn-md border-2 border-dark text-dark" />
					</div>
				</div>
			</div>
			<div>
				<div class="vstack gap-4">
					<div class="card rounded-4 shadow">
						<div class="card-body p-4 lh-sm">
							<div class="mb-1" v-if="group.description">{{ group.description }}</div>
							<div class="text-sm text-muted">
								<template v-if="group.public === true">Sólo publicaciones públicas</template>
								<template v-else-if="group.public === false">Sólo publicaciones privadas</template>
								<template v-else>Todas las publicaciones (públicas y privadas)</template>
							</div>
							<div class="text-sm text-muted">Se creó el {{ createdAt }}</div>
						</div>
					</div>
					<div class="card rounded-4 shadow">
						<div class="card-body p-4 lh-sm">
							<div class="h5 mb-3">Miembros activos</div>
							<AccountGroupUserList :group="group" />
						</div>
					</div>
				</div>
			</div>
			<div class="vstack gap-3">
				<div class="card-footer hstack gap-3 border-0 justify-content-end">
					<router-link :to="{ name: 'account.group.edit', params: { id: group.id }}" class="btn btn-neutral btn-sm rounded-pill" v-if="group.pivot.admin"><i class="bi bi-pen pe-2"></i>Editar</router-link>
					<a href="javascript:void(0);" class="btn btn-danger btn-sm rounded-pill" :disabled="submitting" @click="leaving"><i class="bi bi-box-arrow-right pe-2"></i>Salir del grupo</a>
				</div>
			</div>
		</main>

		<CommonModalAction ref="modalLeaving" title="Salir del grupo" description="¿Desea salir del grupo? Al salir, ya no podrás acceder hasta que alguien te agregue" submitText="Salir" submitClass="btn-danger" :target="group" @success="leave" />
	</LayoutMain>
</template>

<script>
	import { ref, computed, onBeforeMount } from 'vue';
	import router from '@/router';
	import { useRoute } from 'vue-router';
	import moment from 'moment';
	import composableForm from '@/composables/form';
	import AccountGroupRepository from '@/repositories/Account/GroupRepository';

	export default {
		setup() {
			const { loading, submitting, catchError } = composableForm();
			const route = useRoute();
			const group = ref({});
			const createdAt = computed(() => moment(group.value?.created_at).format('DD/MM/YYYY'));

			const modalLeaving = ref(null);
			const leaving = (group) => modalLeaving.value.show();
			const leave = async (group) => {
				submitting.value = true;
				await AccountGroupRepository.leave(group.id).then(() => {
					router.push({ name: `account.group.index` });
				}).catch((e) => catchError(e, true));
				submitting.value = false;
			}

			onBeforeMount(async () => {
				await AccountGroupRepository.show(route.params.id).then((response) => {
					group.value = response.data;
				}).catch((e) => {
					catchError(e, true);
					router.push({ name: `account.group.index` });
				});

				loading.value = false;
			});

			return { route, loading, submitting, group, createdAt, modalLeaving, leaving, leave };
		}
	}
</script>