import Repository from '../Repository';
import { useToast } from 'vue-toastification';

const resource = '/account/group';
const toast = useToast();

export default {
	index(group_id, params) {
		return Repository.get(`${resource}/${group_id}/search`, { params: params });
	},
	categories(group_id) {
		return Repository.get(`${resource}/${group_id}/search/categories`);
	},
	pin(group_id, id) {
		return Repository.post(`${resource}/${group_id}/search/${id}/pin`).then((response) => {
			toast.success(`Búsqueda fijada correctamente.`);
			return response;
		});
	},
	unpin(group_id, id) {
		return Repository.delete(`${resource}/${group_id}/search/${id}/unpin`).then((response) => {
			toast.success(`Búsqueda desfijada correctamente.`);
			return response;
		});
	}
}