<template>
	<LayoutMain>
		<LayoutHeaderSection>
			<template #title>
				<LayoutTextSection :text="(route.params.id) ? 'Editar micrositio' : 'Crear micrositio'" :breadcrumb="true" />
			</template>
		</LayoutHeaderSection>
		<main class="container-md pt-8 vstack gap-10">
			<CommonFormWrapper :submitText="(route.params.id) ? 'Guardar' : 'Crear'" :cancelUrl="{ name: 'admin.microsite.index' }" :submitting="submitting" :errors="validationExternal" @submit="submit" v-if="!loading">
				<template #body>
					<CommonFormInputSelect v-model="microsite.user_id" title="Usuario" placeholder="Seleccionar el usuario" :options="users" optionsType="flat" :validation="validation.user_id" />
					<CommonFormInputText v-model="microsite.name" title="Nombre" placeholder="Ingrese el nombre del sitio" :validation="validation.name" />
					<CommonFormInputText v-model="microsite.host" title="Host" placeholder="Ingrese el host del sitio" :validation="validation.host" />
					<CommonFormInputTextarea v-model="microsite.style" title="Estilos" subtitle="Opcional" placeholder="Pega aquí un objeto con los estilos a aplicar" :validation="validation.style" />
					<CommonFormInputSelect v-model="microsite.status" title="Estado" placeholder="Seleccionar el estado" :options="$env.MODEL.microsite.statuses" :validation="validation.status" />
					<CommonFormInputBoolean v-model="microsite.settings.users" title="Consultar otros perfiles" text="Permitir" :validation="validation.settings?.users" />
					<CommonFormInputBoolean v-model="microsite.settings.groups" title="Consultar grupos" text="Permitir" :validation="validation.settings?.groups" />
					<CommonFormInputImageRaw v-model="microsite.logo_square" title="Logo cuadrado" />
					<CommonFormInputImageRaw v-model="microsite.logo_main" title="Logo principal" />
					<CommonFormInputImageRaw v-model="microsite.logo_secondary" title="Logo secundario" />
					<LayoutFormInputGeneral title="¿Cómo configurar un micrositio?">
						<ul class="list-group list-group-flush">
							<li class="list-group-item py-3 lh-xs">Crea un registro en <b>Amazon Route 53</b> o redirije el dominio al servidor.</li>
							<li class="list-group-item py-3 lh-xs">Agregar el registro tipo <b>A</b> en <b>Amazon Route 53</b>.</li>
							<li class="list-group-item py-3 lh-xs">Agregar el <b>dominio</b> a la variable <b>ADDITIONAL_HOSTS</b> del archivo <b>/.gitlab-ci.yml</b>.</li>
							<li class="list-group-item py-3 lh-xs">Configurar logins en las cuentas de desarrolladores de <b>Google</b> y <b>Apple</b>.</li>
							<li class="list-group-item py-3 lh-xs">Si utiliza mapas, en <b>Google Cloud</b>, agregar la dirección en las credenciales de <b>Claves API</b> para poder utilizar los mapas.</li>
						</ul>
					</LayoutFormInputGeneral>
				</template>
			</CommonFormWrapper>
		</main>
	</LayoutMain>
</template>

<script>
	import { ref, reactive, computed, onBeforeMount } from 'vue';
	import router from '@/router';
	import store from '@/store';
	import { useRoute } from 'vue-router';
	import _ from 'lodash';
	import moment from 'moment';
	import useVuelidate from '@vuelidate/core';
	import { required, minLength, maxLength } from '@/helpers/i18n/validators';
	import composableForm from '@/composables/form';
	import AdminMicrositeRepository from '@/repositories/Admin/MicrositeRepository';

	export default {
		setup() {
			const { loading, submitting, validationExternal, catchError } = composableForm();
			const user = computed(() => store.getters['auth/user']);
			const route = useRoute();
			const users = ref([]);

			const microsite = reactive({
				user_id: null,
				name: null,
				host: null,
				settings: {
					users: false,
					groups: false
				},
				status: 'pending',
				logo_square: null,
				logo_main: null,
				logo_secondary: null
			});

			const validation = useVuelidate({
				name: {
					required,
					minLength: minLength(3),
					maxLength: maxLength(150)
				}
			}, microsite);

			const submit = async () => {
				if(submitting.value) return;
				validationExternal.value = [];
				if(!await validation.value.$validate()) return;

				submitting.value = true;

				if(route.params.id != undefined) {
					await AdminMicrositeRepository.update(route.params.id, microsite).then(() => {
						router.push({ name: 'admin.microsite.index' });
					}).catch(catchError);
				}else{
					await AdminMicrositeRepository.store(microsite).then((response) => {
						router.push({ name: `admin.microsite.index` });
					}).catch(catchError);
				}

				submitting.value = false;
			}

			onBeforeMount(async () => {
				AdminMicrositeRepository.users().then((response) => {
					users.value = _.fromPairs(_.map(response.data, user => [user.id, `${user.name} - ${user.slug}`]));
				}).catch((e) => catchError(e, true));

				if(route.params.id != undefined) {
					await AdminMicrositeRepository.show(route.params.id).then((response) => {
						Object.assign(microsite, response.data);
						microsite.style = JSON.stringify(microsite.style);
					}).catch((e) => {
						catchError(e);
						router.push({ name: `user.index` });
					});
				}

				loading.value = false;
			});

			return { route, loading, users, microsite, submit, submitting, validation, validationExternal };
		}
	}
</script>