<template>
	<td class="position-relative" :class="class">
		<slot />
		<a href="javascript:void(0);" class="stretched-link" @click="select" v-if="hasSelect"></a>
	</td>
</template>

<script>
	import { getCurrentInstance } from 'vue';

	export default {
		emits: ['select'],
		props: {
			class: {
				type: String,
				default: 'py-0'
			}
		},
		setup(props, { emit }) {
			const hasSelect = typeof getCurrentInstance()?.vnode.props?.onSelect === 'function';
			const select = () => emit('select');

			return { hasSelect, select };
		}
	}
</script>