<template>
	<VOnboardingWrapper ref="wrapper" :steps="steps" :options="options">
		<template #default="{ previous, next, step, isFirst, isLast, index }">
			<VOnboardingStep>
				<div class="container-md px-0 px-md-2">
					<div data-v-onboarding-card class="bg-white shadow rounded-md-3 p-4 mx-auto">
						<div class="vstack gap-3">
							<div class="vstack gap-1 lh-xs" v-if="step.content">
								<div class="hstack gap-2 justify-content-between align-items-start">
									<div v-if="step.content.title" class="text-md fw-bold" v-html="step.content.title"></div>
									<a href="javascript:void(0);" @click="end" class="link-danger text-sm ms-auto pt-1">Salir</a>
								</div>
								<div v-if="step.content.description" class="text-sm" v-html="step.content.description"></div>
							</div>
							<div class="hstack gap-4 align-items-center justify-content-between">
								<span class="text-sm text-muted">{{ `Paso ${index + 1} de ${steps.length}` }}</span>
								<div class="btn-group" role="group" aria-label="Basic example">
									<button @click="previous" type="button" class="btn btn-xs btn-neutral px-3 py-1 rounded-start-pill" v-if="!isFirst">Atrás</button>
									<button @click="next" type="button" class="btn btn-xs btn-dark px-3 py-1" :class="{ 'rounded-pill': isFirst, 'rounded-end-pill': !isFirst }" v-if="!isLast">Siguiente</button>
									<button @click="end" type="button" class="btn btn-xs btn-primary px-3 py-1" :class="{ 'rounded-pill': isFirst, 'rounded-end-pill': !isFirst }" v-else>Finalizar</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</VOnboardingStep>
		</template>
	</VOnboardingWrapper>
</template>

<script>
	import { ref, onBeforeUnmount } from 'vue';
	import _ from 'lodash';
	import { VueCookieNext } from 'vue-cookie-next';
	import { VOnboardingWrapper, VOnboardingStep, useVOnboarding } from 'v-onboarding';

	export default {
		components: { VOnboardingWrapper, VOnboardingStep },
		setup() {
			const wrapper = ref(null);
			const timeout = ref(null);
			const { start, finish } = useVOnboarding(wrapper);

			const options = {
				scrollToStep: {
					enabled: false
				},
				popper: {
					modifiers: [
						{
							name: 'offset',
							options: {
								offset: [0, 10]
							}
						}
					]
				}
			};

			const steps = ref([]);
			const stepsComplete = [
				{
					attachTo: {
						element: '.swiper-slide-active #publication-card-main-body',
						classList: ['pointer-events-unset']
					},
					content: {
						title: 'Imagenes de la publicación',
						description: 'Cambiá las imagenes presionando sobre el costado derecho o izquierdo, además podés hacer zoom sobre la imagen.',
						html: true
					},
					options: {
						popper: {
							placement: 'bottom'
						}
					}
				},
				{
					attachTo: {
						element: '.swiper-slide-active #publication-card-main-body',
						classList: ['pointer-events-unset']
					},
					content: {
						title: 'Navegación',
						description: 'Deslizá hacia arriba para ir navegando por las distintas publicaciones.',
						html: true
					},
					options: {
						popper: {
							placement: 'bottom'
						}
					}
				},
				{
					attachTo: {
						element: '.swiper-slide-active #publication-card-main-footer',
						classList: ['pointer-events-unset']
					},
					content: {
						title: 'Descripción general',
						description: 'Aquí encontrarás la descripción general de la publicación, para obtener más información podrás hacer click en esta área de descripción.',
						html: true
					},
					options: {
						scrollToStep: {
							enabled: false
						},
						popper: {
							placement: 'top'
						}
					}
				},
				{
					attachTo: {
						element: '#search-part-action-decision-false',
						classList: ['pointer-events-unset']
					},
					content: {
						title: 'Descartar publicación',
						description: 'Si la publicación no es de tu agrado podrás descartarla, de modo que ya no vuelva a mostrarla en esta búsqueda (podrás deslizar la publicación hacia la izquierda para realizar esta misma acción).',
						html: true
					},
					options: {
						scrollToStep: {
							enabled: false
						},
						popper: {
							placement: 'top'
						},
						overlay: {
							padding: 5,
							borderRadius: 10
						}
					}
				},
				{
					attachTo: {
						element: '#search-part-action-undo',
						classList: ['pointer-events-unset']
					},
					content: {
						title: 'Deshacer tu última desición',
						description: 'En caso de que te haya gustado o hayas descartado una publicación, puedes deshacer tu última decisión presionando éste botón.',
						html: true
					},
					options: {
						scrollToStep: {
							enabled: false
						},
						popper: {
							placement: 'top'
						},
						overlay: {
							padding: 5,
							borderRadius: 10
						}
					}
				},
				{
					attachTo: {
						element: '#search-part-action-decision-map',
						classList: ['pointer-events-unset']
					},
					content: {
						title: 'Ver publicaciones en el mapa',
						description: 'Aquí podrás ver en el mapa, la ubicación de las distintas publicaciones, al presionar en cada una, se mostrará en pantalla la publicación.',
						html: true
					},
					options: {
						scrollToStep: {
							enabled: false
						},
						popper: {
							placement: 'top'
						},
						overlay: {
							padding: 5,
							borderRadius: 10
						}
					}
				},
				{
					attachTo: {
						element: '#search-part-action-decision-true',
						classList: ['pointer-events-unset']
					},
					content: {
						title: 'Guardar publicación',
						description: 'En caso de que te guste la publicación, podrás guardarla haciéndo click en éste botón (podrás deslizar la publicación hacia la derecha para realizar esta misma acción).<br />Además podrás mantener presionado éste botón para enviar un <b>Superlike</b> al encargado de la publicación, junto con tus datos para que pueda contactarte.',
						html: true
					},
					options: {
						scrollToStep: {
							enabled: false
						},
						popper: {
							placement: 'top'
						},
						overlay: {
							padding: 5,
							borderRadius: 10
						}
					}
				},
				{
					attachTo: {
						element: '#search-part-action-option',
						classList: ['pointer-events-unset']
					},
					content: {
						title: 'Más opciones de la búsqueda',
						description: 'Aquí encontrarás más opciones de esta búsqueda, como ver las publicaciones filtradas, compartir la búsqueda, editarla, o enviar el listado de publicaciones que te gustan.',
						html: true
					},
					options: {
						scrollToStep: {
							enabled: false
						},
						popper: {
							placement: 'top'
						},
						overlay: {
							padding: 5,
							borderRadius: 10
						}
					}
				}
			];

			const show = () => {
				steps.value = _.filter(stepsComplete, function(step) { return !!document.querySelector(step.attachTo.element); });
				start();
			}

			const end = () => {
				VueCookieNext.setCookie('guide-search', null, { expire: -1 });
				finish();
			}

			const autostart = () => {
				if(document.querySelector('.modal.show') || document.querySelector('.offcanvas.show')) timeout.value = setTimeout(autostart, 5000);
				else if(!VueCookieNext.isCookieAvailable('guide-search')) show();
			}

			onBeforeUnmount(() => {
				if(timeout.value) clearTimeout(timeout.value);
			});

			return { wrapper, options, steps, show, autostart, end };
		}
	}
</script>