<template>
	<button type="button" class="btn btn-neutral text-dark rounded-pill" :disabled="submitting || !scriptLoaded" @click="login">
		<span class="icon icon-sm pe-1">
			<i class="bi bi-apple"></i>
		</span>
		Apple
	</button>
</template>

<script>
	import { ref, onMounted, inject } from 'vue';
	import store from '@/store';
	import { useRouter } from 'vue-router';
	import { useToast } from 'vue-toastification';
	import composableForm from '@/composables/form';

	export default {
		emits: ['setUser'],
		props: {
			submitting: Boolean
		},
		setup(props, { emit }) {
			const $env = inject('$env');
			const { catchError } = composableForm();
			const router = useRouter();
			const toast = useToast();
			const scriptLoaded = ref(false);

			const loadAppleScript = async () => {
				return new Promise((resolve, reject) => {
					if(document.getElementById('apple-login-script')) {
						return resolve();
					}

					const script = document.createElement('script');
					script.src = 'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js';
					script.id = 'apple-login-script';
					script.onload = () => resolve();
					script.onerror = () => reject(new Error('Error loading Apple script.'));
					document.body.appendChild(script);
				});
			}

			const login = async () => {
				try {
					window.AppleID.auth.signIn().then(function(response) {
						store.dispatch('auth/loginSocial', { provider: 'apple', data: { access_token: response.authorization.id_token }})
							.then(() => emit('setUser'))
							.catch((e) => catchError(e, true));
					});
				}catch(error) {
					toast.error('Error en el login.');
				}
			}

			onMounted(async () => {
				loadAppleScript().then(() => {
					scriptLoaded.value = true;

					window.AppleID.auth.init({
						clientId: $env.APPLE.client_id,
						scope: 'name email',
						redirectURI: `${window.location.origin}${router.resolve({ name: 'auth.social', params: { service: 'apple' }}).href}`,
						usePopup: true
					});
				}).catch((error) => {
					console.error('Apple script error:', error);
				});
			});

			return { scriptLoaded, login };
		}
	}
</script>