<template>
	<LayoutFormInputGeneral :inputId="inputId" :title="title" :subtitle="subtitle" :validation="validation">
		<div class="form-check">
			<input class="form-check-input" type="radio" :name="inputId" :id="`${inputId}-true`" :value="true" v-model="inputValue" @change="emitInput">
			<label class="form-check-label vstack lh-sm pt-1" :for="`${inputId}-true`">
				{{ options['true'].title ?? 'Sí' }}
				<small class="lh-xs" v-if="options['true'].description">{{ options['true'].description }}</small>
			</label>
		</div>
		<div class="form-check">
			<input class="form-check-input" type="radio" :name="inputId" :id="`${inputId}-false`" :value="false" v-model="inputValue" @change="emitInput">
			<label class="form-check-label vstack lh-sm pt-1" :for="`${inputId}-false`">
				{{ options['false'].title ?? 'No' }}
				<small class="lh-xs" v-if="options['false']?.description">{{ options['false'].description }}</small>
			</label>
		</div>
	</LayoutFormInputGeneral>
</template>

<script>
	import { ref, computed } from 'vue';
	import _ from 'lodash';

	export default {
		emits: ['update:modelValue'],
		props: {
			modelValue: {
				type: Boolean,
				default: false
			},
			title: String,
			subtitle: String,
			validation: Object,
			options: {
				type: Object,
				default: {
					true: {
						title: 'Sí'
					},
					false: {
						title: 'No'
					}
				}
			}
		},
		setup(props, { emit }) {
			const inputId = `input-${Math.random().toString(36).substr(2, 9)}`;
			const inputValue = ref(props.modelValue);

			const emitInput = (event) => {
				emit('update:modelValue', Boolean(inputValue.value));
			};

			return { inputId, inputValue, emitInput };
		}
	}
</script>