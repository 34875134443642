import Repository from '../Repository';
import { useToast } from 'vue-toastification';
import { serialize } from 'object-to-formdata';

const resource = '/admin/microsite';
const toast = useToast();

export default {
	index(params) {
		return Repository.get(`${resource}`, { params: params });
	},
	users() {
		return Repository.get(`${resource}/users`);
	},
	show(id) {
		return Repository.get(`${resource}/${id}`);
	},
	store(payload) {
		const formData = serialize(payload, { indices: true, nullsAsUndefineds: true });

		return Repository.post(`${resource}`, formData, { headers: { 'Content-Type': 'multipart/form-data' }}).then((response) => {
			toast.success(`Micrositio cargado correctamente.`);
			return response;
		});
	},
	update(id, payload) {
		const formData = serialize(payload, { indices: true, nullsAsUndefineds: true });
		formData.append('_method', 'PUT');

		return Repository.post(`${resource}/${id}`, formData, { headers: { 'Content-Type': 'multipart/form-data' }}).then((response) => {
			toast.success(`Micrositio actualizado correctamente.`);
			return response;
		});
	},
	destroy(id) {
		return Repository.delete(`${resource}/${id}`).then((response) => {
			toast.success(`Micrositio eliminado correctamente.`);
			return response;
		});
	}
}