<template>
	<div class="hstack gap-2 lh-1">
		<a :href="`mailto:${user.email}`" class="link-dark" :class="`text-${size}`" v-if="user.contact_types.includes('email') && user.email"><i class="bi bi-envelope-at"></i></a>
		<a :href="`tel:${phoneFormatted.number}`" class="link-dark" :class="`text-${size}`" v-if="user.contact_types.includes('phone') && user.phone"><i class="bi bi-telephone-plus"></i></a>
		<a :href="`https://wa.me/${phoneFormatted.number}`" target="_blank" class="link-dark" :class="`text-${size}`" v-if="user.contact_types.includes('whatsapp') && user.phone"><i class="bi bi-whatsapp"></i></a>
	</div>
</template>

<script>
	import { computed } from 'vue';
	import { parsePhoneNumber } from 'libphonenumber-js';

	export default {
		props: {
			user: Object,
			size: {
				type: String,
				default: 'sm'
			}
		},
		setup(props) {
			const phoneFormatted = computed(() => {
				return (props.user?.phone) ? parsePhoneNumber(props.user.phone) : null
			});

			return { phoneFormatted };
		}
	}
</script>