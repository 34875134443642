<template>
	<LayoutFormInputGeneral :inputId="inputId" :title="title" :subtitle="subtitle" :validation="validation">
		<div class="vstack gap-2">
			<Sortable
				ref="sortable"
				:options="{ animation: 300 }"
				:list="inputValue"
				item-key="number"
				tag="div"
				class="row row-cols-3 row-cols-sm-4 row-cols-md-5 g-2"
				@update="sort"
				v-if="inputValue.length > 0"
			>
				<template #item="{ element, index }">
					<div class="col">
						<CommonFormInputPartImage :key="element.number" :media="element" :index="index" @remove="remove" />
					</div>
				</template>
			</Sortable>
			<div>
				<label :for="inputId" class="btn btn-sm btn-neutral rounded-pill">
					<span>Seleccionar imagenes</span>
					<input type="file"
						:id="inputId"
						multiple="multiple"
						accept="image/jpg, image/jpeg, image/png"
						@change="add"
						class="visually-hidden">
				</label>
			</div>
		</div>
	</LayoutFormInputGeneral>
</template>

<script>
	import { ref, watch } from 'vue';
	import _ from 'lodash';
	import { Sortable } from 'sortablejs-vue3';

	export default {
		components: { Sortable },
		emits: ['update:modelValue'],
		props: {
			modelValue: Array,
			title: String,
			subtitle: String,
			placeholder: String,
			validation: Object,
			focus: {
				type: Boolean,
				default: false
			},
			submitting: {
				type: Boolean,
				default: false
			}
		},
		setup(props, { emit }) {
			const inputId = `input-${Math.random().toString(36).substr(2, 9)}`;
			const sortable = ref(null);
			const inputValue = ref(props.modelValue);

			const add = (e) => {
				let number = _.maxBy(inputValue.value, 'number')?.number ?? 0;

				_.forEach(e.target.files, (file) => {
					number++;
					inputValue.value.push({
						id: null,
						type: 'image',
						location: 'local',
						file: file,
						number: number
					});
				});
			}

			const remove = (index) => {
				_.pullAt(inputValue.value, index);
			}

			const sort = (event) => {
				const item = inputValue.value.splice(event.oldIndex, 1)[0];
				inputValue.value.splice(event.newIndex, 0, item);
			}

			watch(inputValue.value, (newValue) => {
				emit('update:modelValue', newValue);
			});

			return { inputId, sortable, inputValue, add, remove, sort };
		}
	}
</script>

<style scoped>
	.media-wrapper {
		width: 100%;
		aspect-ratio: 1 / 1;
	}

	.image {
		width: 6rem;
		min-width: 6rem !important;
		aspect-ratio: 1 / 1.4;
	}
</style>