<template>
	<LayoutTableCommonRow>
		<LayoutTableCommonPartRaw class="">
			<div class="hstack gap-4 justify-content-between align-items-baseline lh-1 mb-2">
				<div class="vstack gap-1 position-relative">
					<div class="text-sm text-muted">{{ dateDiff }}</div>
					<a href="javascript:void(0)" class="h5 stretched-link lh-xs" @click="displayBody = !displayBody">{{ search.title_suggested }}</a>
				</div>
				<AccountGroupSearchTablePartOption :search="search" :user="user" @remove="remove" @reload="reload" />
			</div>
			<Transition name="fade-flash" mode="out-in">
				<div class="vstack gap-1 mt-1 mb-4" v-if="displayBody">
					<div v-for="(filter, index) in search.filters" :key="index">
						<AccountGroupSearchTablePartFilter :filter="filter" />
					</div>
					<AccountGroupSearchTablePartPlaces :places="search.places" v-if="search.places.length > 0" />
					<AccountGroupSearchTablePartDescription :description="search.description" v-if="search.description" />
				</div>
			</Transition>
			<div class="vstack gap-2">
				<UserCardSmall v-for="user in search.users" :key="user.id" :user="user" :contactModal="true" :contactInline="true" />
			</div>
		</LayoutTableCommonPartRaw>
	</LayoutTableCommonRow>
</template>

<script>
	import { ref, inject } from 'vue';
	import _ from 'lodash';
	import moment from 'moment';

	export default {
		emits: ['reload'],
		props: {
			search: Object,
			user: Object
		},
		setup(props, { emit }) {
			const $env = inject('$env');
			const remove = () => emit('remove', props.search);
			const displayBody = ref(false);
			const dateDiff = moment(props.search.created_at).fromNow();
			const reload = () => emit('reload');

			return { remove, displayBody, dateDiff, reload };
		}
	}
</script>